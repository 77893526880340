import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import {
    Grid,
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Button,
    Typography,
    makeStyles
} from '@material-ui/core';
import axios from 'axios';
import firebase from 'firebase';
import * as validator from "email-validator";

const REGISTER_INITIAL_TEAM_PRIMARY = "/addNewTeamAndPrimary"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(30),
      marginRight: theme.spacing(30),
    },
}));

export default function AddTeamAndPrimary(){

    const classes = useStyles();

    const [redirect, setRedirect] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState("/");
    const [isAdmin, setIsAdmin] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
              user.getIdTokenResult()
              .then((idTokenResult) => {
                  if (!idTokenResult.claims.admin) {
                      setRedirectUrl("/");
                      setRedirect(true);
                      setIsAdmin(false);
                  } else {
                      setRedirect(false);
                      setIsAdmin(true);
                  }
              })
              .catch((error) => {
                  setRedirectUrl("/");
                  setRedirect(true);
                  setIsAdmin(false);
              });
          }
          else{
              setRedirectUrl("/login");
              setRedirect(true);
          }
          });
    }, [])

    const [companyName, setCompanyName] = useState("");
    const [teamName, setTeamName] = useState("");
    const [primaryEmail, setPrimaryEmail] = useState("");
    const [primaryName, setPrimaryName] = useState("");
    const [primaryPass, setPrimaryPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [isTeamAdmin, setIsTeamAdmin] = useState(false);

    const handleCompanyNameTextFieldChange = (e) => {
        setCompanyName(e.target.value);
    }

    const handleTeamNameTextFieldChange = (e) => {
        setTeamName(e.target.value);
    }

    const handlePrimaryEmailTextFieldChange = (e) => {
        setPrimaryEmail(e.target.value);
        setEmailError(null);
    }

    const handlePrimaryNameTextFieldChange = (e) => {
        setPrimaryName(e.target.value);
    }

    const handlePrimaryPassTextFieldChange = (e) => {
        setPrimaryPass(e.target.value);
        setPasswordError(null);
        setConfirmPass("");
    }

    const handleConfirmPassTextFieldChange = (e) => {
        setConfirmPass(e.target.value);
        if (e.target.value !== primaryPass) {
          setPasswordError("Passwords must match");
        } else {
          setPasswordError(null);
        }
    }

    const handleSubmit = () => {
        if (!validator.validate(primaryEmail)) {
          setEmailError("Please enter a valid email address");
        } else {
          axios.post("/api" + REGISTER_INITIAL_TEAM_PRIMARY, {
              companyName: companyName,
              teamName: teamName,
              isTeamAdmin: isTeamAdmin,
              primaryEmail: primaryEmail,
              primaryName: primaryName,
              primaryPass: primaryPass
          })
          .then(res => {
              alert(res.data);
              setCompanyName("");
              setTeamName("");
              setIsTeamAdmin(false);
              setPrimaryEmail("");
              setPrimaryName("");
              setPrimaryPass("");
              setConfirmPass("");
          })
          .catch(err => {
            console.log(err);
          })
      }
    }

    return(
        <>
        {redirect && <Redirect push to={redirect} />}
        {isAdmin &&
        <Grid container direction='column' className={classes.root} spacing={3}>
            <Grid item xs={9}>
                <Typography variant="h4">Add New Team and Primary Contact</Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField fullWidth={true} id="companyName" label="Company name" onChange={handleCompanyNameTextFieldChange} value={companyName}/>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6}>
                  <TextField fullWidth={true} id="teamName" label="Team name" onChange={handleTeamNameTextFieldChange} value={teamName}/>
              </Grid>
              <Grid item>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox fullWidth={true} variant="outlined" id="teamAdmin" label="Team Admin" onChange={(e) => setIsTeamAdmin(e.target.checked)} value={isTeamAdmin}/>
                    }
                    label="Make Team Admin"
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth={true}
                id="primaryEmail"
                label="Primary Contact Email"
                helperText={emailError}
                error={emailError !== null}
                onChange={handlePrimaryEmailTextFieldChange}
                value={primaryEmail}
              />
            </Grid>
            <Grid item xs={7}>
                <TextField fullWidth={true} id="primaryName" label="Primary Contact Name" onChange={handlePrimaryNameTextFieldChange} value={primaryName}/>
            </Grid>
            <Grid item xs={7}>
                <TextField fullWidth={true} id="primaryPass" label="Primary Contact Password" error={passwordError !== null} onChange={handlePrimaryPassTextFieldChange} value={primaryPass}/>
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth={true}
                id="confirmPass"
                label="Confirm Contact Password"
                helperText={passwordError}
                error={passwordError !== null}
                onChange={handleConfirmPassTextFieldChange}
                value={confirmPass}
              />
            </Grid>
            <Grid item xs={7}>
              <Button
                variant="outlined"
                disabled={
                  (companyName.length === 0 || teamName === 0 || primaryEmail.length === 0 ||
                  primaryName.length === 0 || primaryPass.length === 0 || confirmPass.length === 0) ||
                  (emailError !== null || passwordError !== null)
                }
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
        </Grid>
        }
        </>
    );

}
