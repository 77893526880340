/* DashboardView.tsx
 * This file renders the main dashboard for BomBuilder. When the website is
 * opened to the index endpoint (/), this is rendered. It shows a dashboard
 * of the latest 20 jobs, as well as the ability to search for a past job,
 * or create a new job.
 */

import React from "react";
import { Redirect } from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/auth";
import {
  Grid,
  makeStyles,
  Hidden,
  Paper,
  Theme,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Account from "./static/Account";
import SideBar from "./static/SideBar";
import Home from "./static/Home";
import QuoteView from "./components/QuoteView/QuoteView";
import ContactDialog from "./common/ContactDialog";
import TicketCard from "./common/TicketCard";
import Settings from "./static/Settings";

// import components
import CustomerStatus from "./components/OrderStatus/CustomerStatus";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: "100vw",
    minHeight: "100vh",
  },
  close: {
    padding: theme.spacing(0.5),
  },
  dashboardHeader: {
    fontSize: 35,
    fontWeight: "bold",
  },
  header: {
    marginBottom: 50,
  },
  dialog: {
    minWidth: 800,
    margin: theme.spacing(1),
  },
  spacing: {
    margin: theme.spacing(1),
  },
  flows: {
    margin: 30,
  },
  italics: {
    fontStyle: "italic",
  },
  metadata: {
    backgroundColor: "#F4F4F4",
    paddingBottom: 50,
  },
  capabilities: {
    marginTop: theme.spacing(2),
  },
  spec: {
    fontWeight: "bold",
  },
}));

function DashboardView(props) {
  const classes = useStyles();
  const [contact, setContact] = React.useState(false);

  const [redirect, setRedirect] = React.useState(false);
  const [activeComponent, setActiveComponent] = React.useState("home");

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(function(currUser) {
      if (!currUser) {
        setRedirect(true);
      } else {
        firebase.analytics().logEvent('Home', {email: currUser.email});
      }
    });
    setActiveComponent(props.activeComponent);
  }, [props]);

  return (
    <>
    {redirect && <Redirect to="/login" />}
    <Grid container className={classes.root}>
      <Grid item xs={1}>
        <Hidden smDown>
          <SideBar
            clickHome={() => {
              props.handleActiveComponentChange("home");
            }}
            clickStatus={() => {
              props.handleActiveComponentChange("orderStatus");
            }}
            clickQuote={() => {
              props.handleActiveComponentChange("quote");
            }}
            clickEmail={() => setContact(true)}
            clickSettings={() => {
              props.handleActiveComponentChange("settings");
            }}
          />
        </Hidden>
      </Grid>
      <Grid item xs>
        <Grid container direction="column">
          <Grid item xs={12}>
            {activeComponent === "home" &&
              <Home />
            }
            {/*This should change to Order Status page*/}
            {activeComponent === "orderStatus" &&
              <div style={{marginTop: 8, marginLeft: "5vw"}}>
                {/* <Typography style={{fontSize: 35, fontWeight: "bold"}}>
                  Order Status
                </Typography> */}
                <Redirect push to={"/customer"} />
              </div>
            }
            {activeComponent === "quote" &&
              <QuoteView />
            }
            {/*
            {zendeskTickets.length > 0 &&
              zendeskTickets.map((ticketObj) => {
                let ticket = ticketObj.ticket;
                let comments = ticketObj.comments;
                return (
                  <TicketCard
                    description={ticket.description}
                    createdAt={ticket.created_at}
                    subject={ticket.subject}
                    type={ticket.type}
                    updatedAt={ticket.updated_at}
                    orderNum={ticket.fields[1].value}
                    comments = {ticketObj.comments}
                  />
                )
              })
            }*/}
            {activeComponent === "account" && <Account/>}
            {activeComponent === "settings" && <Settings/>}
          </Grid>
        </Grid>
      </Grid>
      <ContactDialog open={contact} onClose={() => setContact(false)}/>
    </Grid>
    </>
  );
}

export default DashboardView;
