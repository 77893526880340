/* App.tsx
 * This file renders the BomBuilder application. Some overall components are
 * initialized here, like the SnackbarProvider, BackendHealth, and others.
 * There are also hotkeys to upload a file of packages / druid locks.
 * We can see the endpoints are routed into components here, such that the
 * index endpoint (/) renders <CreateProject />, and a specific job endpoint
 * (/job/<job_id>) renders <JobView />
 */

import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme, Theme, ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import dotenv from "dotenv";

import {FIREBASE_API_KEY, FIREBASE_AUTH_DOMAIN, FIREBASE_DATABASE_URL, FIREBASE_PROJECT_ID, FIREBASE_STORAGE_BUCKET, FIREBASE_MESSAGING_SENDER_ID, FIREBASE_APP_ID, FIREBASE_MEASUREMENT_ID} from './constants';

// Components
//import FileUploadView from "./FileUploadView";
import QuoteView from "./components/QuoteView/QuoteView";
import DashboardView from "./DashboardView";
import QuotePdfView from "./QuotePdfView";
import HeaderBar from "./static/HeaderBar";
import FooterBar from "./static/FooterBar";
import OrderStatus from "./components/OrderStatus/OrderStatus";
import CustomerStatus from "./components/OrderStatus/CustomerStatus";
import Login from "./static/Login";
import Register from "./static/Register";
import Admin from './components/Admin/Admin';
import AddAdminFromEmail from './components/Admin/AddAdminFromEmail';
import AddTeamAndPrimary from './components/Admin/AddTeamAndPrimary';
import EditContact from './components/Admin/EditContact';

dotenv.config();

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [activeComponent, setActiveComponent] = React.useState("home");
  const theme: Theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            light: "#FFA86F",
            main: "#EA723D",
            dark: "#AC3E09",
          },
          secondary: {
            light: "#73C8FF",
            main: "#1893FA",
            dark: "#0061C1",
          },
        },
      }),
    [prefersDarkMode]
  );

  const handleActiveComponentChange = (component) => {
    setActiveComponent(component);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        maxSnack={2}
      >
        <BrowserRouter>
          <HeaderBar activeComponent={activeComponent} handleActiveComponentChange={handleActiveComponentChange}/>
          <Switch>
            <Route exact path="/admin">
              <Admin handleActiveComponentChange={handleActiveComponentChange}/>
            </Route>
            <Route path="/admin/addAdminFromEmail">
                <AddAdminFromEmail/>
            </Route>
            <Route path='/admin/addTeamAndPrimaryContact'>
                <AddTeamAndPrimary />
            </Route>
            <Route path="/admin/editContact">
                <EditContact/>
            </Route>
            <Route exact path={["/","/orderStatus/"]}>
              <DashboardView handleActiveComponentChange={handleActiveComponentChange} activeComponent={activeComponent} />
            </Route>
            <Route path="/quote">
              <QuoteView />
            </Route>
            <Route path="/quotepdf">
              <QuotePdfView />
            </Route>
            <Route path="/login" render={(props) => <Login {...props}/>} />
            <Route path="/orderStatus/:uniqueOrderId">
              <OrderStatus />
            </Route>
            <Route path="/customer">
              <CustomerStatus />
            </Route>
            <Route path="/register/:emailId/:senderUid">
              <Register />
            </Route>
          </Switch>
        </BrowserRouter>
        <FooterBar />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
