import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/auth";
import axios from 'axios';
import MuiPhoneNumber from "material-ui-phone-number";


import {
    Grid,
    Switch,
    makeStyles,
    Typography,
    TextField,
    Button
} from '@material-ui/core';

import{
    Cancel,
    Save,
    Edit,
    ChangeCircleRounded,
    FactCheckRounded,
    LocalShippingRounded
} from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "95%",
        marginLeft: "5%",
        marginTop: "3%"
    },
    pageTitle: {
        fontSize: "3em",
        fontWeight: "bold"
    },
    sectionTitle: {
        fontSize: "2em",
        fontWeight: "bold"
    },
    editContainers: {
        marginTop: "2%"
    },
    notificationTypesHeader: {
        fontSize: "1.5em",
        fontWeight: "bold"
    }
}));

export default function Settings(props){

    const GET_NOTIFICATION_INFO_ENDPOINT = '/getNotificationInformation';
    const SAVE_NEW_NOTIFICATION_PHONE_ENDPOINT = '/saveNewNotificationPhone';
    const SAVE_TEXT_NOTIFICATION_PREFERENCE_ENDPOINT = '/saveTextNotificationPreference';
    const SAVE_NEW_NOTIFICATION_EMAIL_ENDPOINT = '/saveNewNotificationEmail';
    const SAVE_EMAIL_NOTIFICATION_PREFERENCE_ENDPOINT = '/saveEmailNotificationPreference';

    const classes = useStyles();

    const [textNotificationsSwitchChecked, setTextNotificationsSwitchChecked] = useState(false);
    const [emailNotificationsSwitchChecked, setEmailNotificationsSwitchChecked] = useState(false);
    const [textNotificationTextBoxEnabled, setTextNotificationTextBoxEnabled] = useState(false);
    const [emailNotificationTextBoxEnabled, setEmailNotificationTextBoxEnabled] = useState(false);
    const [notificationPhone, setNotificationPhone] = useState('+1');
    const [notificationPhoneTextBoxValue, setNotificationPhoneTextBoxValue] = useState('');
    const [notificationEmail, setNotificationEmail] = useState('');
    const [notificationEmailTextBoxValue, setNotificationEmailTextBoxValue] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState("/login");
    const [user, setUser] = useState(null);

    const handleTextNotificationsSwitchChange = (e) => {
        const checked = e.target.checked;
        setTextNotificationsSwitchChecked(checked);
        axios.post("/api" + SAVE_TEXT_NOTIFICATION_PREFERENCE_ENDPOINT, {
            receiveTextNotifications: checked,
            uid: user.uid
        })
        .catch(err => {
            setTextNotificationsSwitchChecked(err.response.data);
            alert("There was a problem setting the text notification preference, please try again");
        })
    }

    const handleEmailNotificationsSwitchChange = (e) => {
        setEmailNotificationsSwitchChecked(e.target.checked);

        const checked = e.target.checked;
        setEmailNotificationsSwitchChecked(checked);
        axios.post("/api" + SAVE_EMAIL_NOTIFICATION_PREFERENCE_ENDPOINT, {
            receiveEmailNotifications: checked,
            uid: user.uid
        })
        .catch(err => {
            setEmailNotificationsSwitchChecked(err.response.data);
            alert("There was a problem setting the email notification preference, please try again");
        })
    }

    const handlePhoneNotificationCancelEdit = () => {
        setNotificationPhoneTextBoxValue(notificationPhone);
        setTextNotificationTextBoxEnabled(false);
    }

    const handleEmailNotificationCancelEdit = () => {
        setNotificationEmailTextBoxValue(notificationEmail);
        setEmailNotificationTextBoxEnabled(false);
    }

    const handlePhoneNotificationEditSave = () => {
        if(notificationPhoneTextBoxValue.length !== 12){
            alert('Please enter a valid US phone number');
            return;
        }
        axios.post("/api" + SAVE_NEW_NOTIFICATION_PHONE_ENDPOINT, {
            phoneNumber: notificationPhoneTextBoxValue,
            uid: user.uid
        })
        .then(res => {
            setNotificationPhone(res.data);
            setTextNotificationTextBoxEnabled(false);
        })
        .catch(err => {
            setNotificationPhoneTextBoxValue(notificationPhone);
            alert("There was an error trying to save the new number, please try again.");
        })
    }

    const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
        return true;
    }
    alert("You have entered an invalid email address!");
    return false;
    }

    const handleEmailNotificationEditSave = () => {
        if(!validateEmail(notificationEmailTextBoxValue))   return;
        axios.post("/api" + SAVE_NEW_NOTIFICATION_EMAIL_ENDPOINT, {
            email: notificationEmailTextBoxValue,
            uid: user.uid
        })
        .then(res => {
            setNotificationEmail(res.data);
            setEmailNotificationTextBoxEnabled(false);
        })
        .catch(err => {
            setNotificationEmailTextBoxValue(notificationEmail);
            alert("There was an error trying to save the new email, please try again.");
        })
    }

    const handleTextNotificationTextBoxValueChange = (value) => {
        if(value.length > 12){
            return;
        }
        else{
            setNotificationPhoneTextBoxValue(value);
        }
    }

    useEffect(() => {
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                setUser(user);
                axios.get("/api" + GET_NOTIFICATION_INFO_ENDPOINT + `/${user.uid}`)
                .then(res => {
                    const data = res.data;
                    if(data.notificationPhone !== ""){
                        setNotificationPhone(data.notificationPhone);
                    }
                    setNotificationPhoneTextBoxValue(data.notificationPhone);
                    setNotificationEmail(data.notificationEmail);
                    setNotificationEmailTextBoxValue(data.notificationEmail);
                    setTextNotificationsSwitchChecked(data.receiveTextNotifications);
                    setEmailNotificationsSwitchChecked(data.receiveEmailNotifications);
                })
                .catch(err => {
                    alert("There was a problem getting the data. Please refresh the page");
                })
            }
            else{
                setRedirectUrl("/login");
                setRedirect(true);
            }
          });
    }, []);

    const notificationTypes = [{
            icon: <FactCheckRounded sx={{fontSize: 50}} color='success'/>,
            title: 'Order Confirmation',
            description: "You'll be notified whenever any of your orders are confirmed."
        },
        {
            icon: <ChangeCircleRounded sx={{fontSize: 50}} color='info'/>,
            title: 'Order Status Change',
            description: "You'll be notified whenever there is any change to the status of your order. (ex. In Production to Shipped)"
        },
        {
            icon: <LocalShippingRounded sx={{fontSize: 50}} color='info' />,
            title: 'Ship Date Change',
            description: "You'll be notified whenever the shipping date of any of your orders changes."
        }
    ]

    return(
        <>
        {redirect && <Redirect to={redirectUrl} />}
        <Grid container className={classes.root} spacing={3}>
            <Grid item container direction='column' spacing={5} xs={12} lg={8}>
                <Grid item>
                    <Typography className={classes.pageTitle}>Settings</Typography>
                </Grid>

                {/* text notification settings */}
                <Grid item style={{marginBottom: "5%"}}>
                    <Grid container alignItems="center" >
                        <Grid item xs={9}>
                            <Typography className={classes.sectionTitle}>Text Notifications</Typography>
                        </Grid>
                        <Grid item container xs={3}>
                            <Switch
                                checked={textNotificationsSwitchChecked}
                                onChange={handleTextNotificationsSwitchChange}
                                name="notificationSwitch"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                color='primary'
                            />
                        </Grid>
                    </Grid>
                    { textNotificationsSwitchChecked &&
                        <Grid container spacing={5} className={classes.editContainers}>
                            <Grid item xs={12} md={6}>
                            <MuiPhoneNumber
                                name="phone"
                                label="Phone Number"
                                defaultCountry={"us"}
                                value={notificationPhoneTextBoxValue}
                                onChange={handleTextNotificationTextBoxValueChange}
                                onlyCountries={['us']}
                                countryCodeEditable={false}
                                disableAreaCodes={true}
                                autoFormat={false}
                                variant="outlined"
                                disabled={!textNotificationTextBoxEnabled}
                                error={notificationPhone.length < 12}
                                helperText={(notificationPhone.length < 12) ? 'We do not have a notification phone number on file. Please enter a valid US phone number to receive text notifications.' : ''}
                                fullWidth
                            />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        {textNotificationTextBoxEnabled && 
                                            <Button
                                                onClick={handlePhoneNotificationCancelEdit}
                                                startIcon={<Cancel />}
                                                variant="outlined"
                                                style={{color: "red"}}
                                            >
                                                Cancel
                                            </Button>
                                        }
                                        {!textNotificationTextBoxEnabled &&
                                            <Button
                                                onClick={() => setTextNotificationTextBoxEnabled(true)}
                                                startIcon={<Edit />}
                                                variant="outlined"
                                            >
                                                Edit
                                            </Button>
                                        }
                                    </Grid>
                                    {textNotificationTextBoxEnabled &&
                                        <Grid item xs={6}>
                                            <Button
                                                onClick={handlePhoneNotificationEditSave}
                                                startIcon={<Save />}
                                                variant="outlined"
                                                color="secondary"
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>

                {/* email notification settings */}
                <Grid item>
                    <Grid container alignItems="center" >
                        <Grid item xs={9}>
                            <Typography className={classes.sectionTitle}>Email Notifications</Typography>
                        </Grid>
                        <Grid item container xs={3}>
                            <Switch
                                checked={emailNotificationsSwitchChecked}
                                onChange={handleEmailNotificationsSwitchChange}
                                name="notificationSwitch"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                color='primary'
                            />
                        </Grid>
                    </Grid>
                    { emailNotificationsSwitchChecked &&
                        <Grid container spacing={5} className={classes.editContainers}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    disabled={!emailNotificationTextBoxEnabled}
                                    value={notificationEmailTextBoxValue}
                                    onChange={(e) => setNotificationEmailTextBoxValue(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        {emailNotificationTextBoxEnabled && 
                                            <Button
                                                onClick={handleEmailNotificationCancelEdit}
                                                startIcon={<Cancel />}
                                                variant="outlined"
                                                style={{color: "red"}}
                                            >
                                                Cancel
                                            </Button>
                                        }
                                        {!emailNotificationTextBoxEnabled &&
                                            <Button
                                                onClick={() => setEmailNotificationTextBoxEnabled(true)}
                                                startIcon={<Edit />}
                                                variant="outlined"
                                            >
                                                Edit
                                            </Button>
                                        }
                                    </Grid>
                                    {emailNotificationTextBoxEnabled &&
                                        <Grid item xs={6}>
                                            <Button
                                                onClick={handleEmailNotificationEditSave}
                                                startIcon={<Save />}
                                                variant="outlined"
                                                color="secondary"
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item container direction='column' xs={12} lg={4} align="center" alignItems="center" spacing={5} style={{marginBottom: "5%"}} >
                <Grid item><Typography className={classes.sectionTitle}>Notification Types</Typography></Grid>
                {
                    notificationTypes.map(notification => {
                        return(
                            <Grid item container direction='column'>
                                <Grid item>
                                    {notification.icon}
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.notificationTypesHeader}>{notification.title}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>{notification.description}</Typography>
                                </Grid>
                            </Grid>
                        )}
                    )
                }
            </Grid>
        </Grid>
        </>
    );
}