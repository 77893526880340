import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Theme,
  Paper
} from "@material-ui/core";
import moment from "moment";
import OrderPreview from "./OrderPreview";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxHeight: 750,
  }
}));

interface TableProps {
  data: any[];
  showCustomer: boolean;
}

function OrderTable({ data, showCustomer }: TableProps) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Order No.</TableCell>
            <TableCell>Part Number</TableCell>
            <TableCell>PO Number</TableCell>
            <TableCell>Order Date</TableCell>
            <TableCell>Status</TableCell>
            {showCustomer && <TableCell>Customer</TableCell>}
            <TableCell>Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((order) =>
            <OrderPreview key={order.ReferenceNumber} order={order} showCustomer={showCustomer} />
          )}
        </TableBody>
      </Table>
    </TableContainer>

  );

}

export default OrderTable;
