import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import {
    Grid,
    TextField,
    Button,
    Typography,
    makeStyles
} from '@material-ui/core';
import axios from 'axios';
import firebase from 'firebase';
import * as validator from "email-validator";
import { SettingsSystemDaydream } from '@material-ui/icons';

const GET_USER_INFO_FROM_EMAIL = "/getUserInfoFromEmail";
const EDIT_USER_TEAM_AND_COMPANY= "/editUserTeamIdCompany";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(30),
      marginRight: theme.spacing(30),
    },
}));

export default function EditContact(){

    const classes = useStyles();

    const [redirect, setRedirect] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState("/");
    const [isAdmin, setIsAdmin] = useState(false);

    const [email, setEmail] = useState("");
    const [user, setUser] = useState(null);
    const [company, setCompany] = useState("");
    const [teamId, setTeamId] = useState("");

    const [emailError, setEmailError] = useState(null);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
              user.getIdTokenResult()
              .then((idTokenResult) => {
                  if (!idTokenResult.claims.admin) {
                      setRedirectUrl("/");
                      setRedirect(true);
                      setIsAdmin(false);
                  } else {
                      setRedirect(false);
                      setIsAdmin(true);
                  }
              })
              .catch((error) => {
                  setRedirectUrl("/");
                  setRedirect(true);
                  setIsAdmin(false);
              });
          }
          else{
              setRedirectUrl("/login");
              setRedirect(true);
          }
          });
    }, [])

    const handleEmail = (e) => {
      setEmailError(null);
      setEmail(e.target.value);
    }

    const handleSearchClick = () => {
      if (!validator.validate(email)) {
        setEmailError("Please enter a valid email address");
      } else {
        axios.get("/api" + GET_USER_INFO_FROM_EMAIL + `/${email}`)
        .then(res => {
            console.log(res.data);
            setUser(res.data);
            setCompany(res.data.company);
            setTeamId(res.data.teamId)
        })
        .catch(err => {
            alert(err.response.data);
        })
      }
    }

    const handleSave = () => {
        console.log("user", user)
        if(company === "" || teamId === ""){
            alert("company or team id cannot be empty");
            return;
        }
        if(company.includes("/") || teamId.includes("/")){
            alert("company and team id cannot contain the '/' character");
            return;
        }
        if(company === user.company && teamId === user.teamId){
            return;
        }
        axios.post("/api" + EDIT_USER_TEAM_AND_COMPANY, {
            uid: user.uid,
            teamId: teamId,
            company: company
        })
        .then(res => {
            alert("successfully updated the user team id and company");
        })
        .catch(err => {
            alert(err.response.data);
        })
    }

    return(
        <>
        {redirect && <Redirect  push to={"/"}/>}
        {isAdmin &&
        <Grid container direction='column' spacing={3} className={classes.root}>
            <Grid container item xs={9}>
                <Typography variant="h4">Edit Contact</Typography>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={7}>
                    <TextField
                        id="search"
                        name="search"
                        label="Enter email to search for the user"
                        variant="outlined"
                        helperText={emailError}
                        error={emailError !== null}
                        value={email}
                        onChange={handleEmail}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={7}>
                    <Button variant="outlined" onClick={handleSearchClick}>Search</Button>
                </Grid>
            </Grid>
            {user !== null &&
            <Grid container item xs={12} spacing={2} direction='column'>
                <Grid item>
                    <TextField
                        id='company'
                        name="company"
                        label="Company"
                        variant="outlined"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id='teamId'
                        name="teamId"
                        label="Team ID"
                        variant="outlined"
                        value={teamId}
                        onChange={(e) => setTeamId(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <Button onClick={handleSave} variant="outlined">Save</Button>
                </Grid>
            </Grid>
            }
        </Grid>
        }
        </>
    );
}
