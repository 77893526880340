import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography
} from "@material-ui/core";
import { useFormik } from 'formik';
import * as yup from 'yup';
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import FormData from "form-data";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 600,
    margin: theme.spacing(1),
  },
  close: {
    padding: theme.spacing(0.5),
  },
  dashboardHeader: {
    fontSize: 35,
    fontWeight: "bold",
  },
  header: {
    marginBottom: 50,
  },
  dialog: {
    minWidth: 800,
    margin: theme.spacing(1),
  },
  spacing: {
    margin: theme.spacing(1),
  },
  flows: {
    margin: 30,
  },
  italics: {
    fontStyle: "italic",
  },
  metadata: {
    backgroundColor: "#F4F4F4",
    paddingBottom: 50,
  },
  capabilities: {
    marginTop: theme.spacing(2),
  },
  spec: {
    fontWeight: "bold",
  },
}));

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  name: yup
    .string('Enter your full name')
    .required('Name is required'),
  subject: yup
    .string('Enter the subject of your message')
    .required('Subject is required'),
  message: yup
    .string('Enter a message')
    .required('Message is required'),
});

function ContactDialog(props) {
  const classes = useStyles();
  const [submitted, setSubmitted] = React.useState(false);
  const [submitMsg, setSubmitMsg] = React.useState("");
  const [dialogTitle, setDialogTitle] = React.useState("Need Help?");
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      message: '',
      orderNum: null,
      file: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmitContact(values);
    },
  });

  const handleSubmitContact = async (data) => {
    var form = new FormData();
    form.append('name', data.name);
    form.append('email', data.email);
    form.append('subject', data.subject);
    form.append('message', data.message);
    if (data.orderNum) {
      form.append('orderNum', data.orderNum);
    }
    if (data.file) {
      form.append('file', data.file);
    }
    axios({
      method: "post",
      url: '/api/contactUs',
      data: form,
      config: { headers: {'content-type': 'multipart/form-data' }}
      })
      .then(response => {
        setSubmitted(true);
        setSubmitMsg(`Thank you for reaching out! We are reviewing your submission and
        will respond within 24 hours.`);
        setDialogTitle("Submission Successful!");
      })
      .catch(err => {
        setSubmitted(true);
        let errMsg = err.response.data.error;
        setSubmitMsg(`We were unable to process your request. Please refresh
        the page and try again! You can also call us at (831) 636 7789 or send
        an email to sales@aapcb.com.`);
        setDialogTitle("Submission Unsuccessful");
      });


  };

  const handleClose = () => {
    props.onClose();
    setTimeout(() => {
      setSubmitted(false);
      setSubmitMsg('');
      setDialogTitle('Need Help?');
    }, 1000);
  };

  return (
    <Dialog open={props.open}  maxWidth="md" className={classes.dialog}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      { submitted &&
        <React.Fragment>
          <DialogContent>
            {submitMsg}
          </DialogContent>
          <DialogActions className={classes.spacing}>
            <Button variant="outlined" onClick={handleClose}
                    className={classes.spacing}
            >
              Close
            </Button>
          </DialogActions>
        </React.Fragment>
      }
      { !submitted &&
        <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Typography paragraph>
            Fill out the form below and we'll get back to you within 24 hours!
          </Typography>
            <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="name"
                name="name"
                label="Full Name *"
                variant="outlined"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                id="email"
                name="email"
                label="Email *"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="subject"
                name="subject"
                label="Subject *"
                variant="outlined"
                value={formik.values.subject}
                onChange={formik.handleChange}
                error={formik.touched.subject && Boolean(formik.errors.subject)}
                helperText={formik.touched.subject && formik.errors.subject}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                id="orderNum"
                name="orderNum"
                label="Order Number"
                variant="outlined"
                value={formik.values.orderNum}
                onChange={formik.handleChange}
                error={formik.touched.orderNum && Boolean(formik.errors.orderNum)}
                helperText={formik.touched.orderNum && formik.errors.orderNum}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="message"
                name="message"
                label="message *"
                variant="outlined"
                multiline
                rows={5}
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.orderNum)}
                helperText={formik.touched.message && formik.errors.message}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                component="label"
              >
                Upload File
                <input id="file" name="file" type="file" hidden
                       onChange={(event) => {
                        formik.setFieldValue("file", event.currentTarget.files[0]);
                       }}
                />
              </Button>
              <span style={{marginLeft: 8, fontSize: 18}}>
                {formik.values.file && formik.values.file.name}
              </span>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.spacing}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            endIcon={<SendIcon />}
            disabled={!formik.values.name || !formik.values.email ||
                      !formik.values.subject || !formik.values.message}
          >
            Submit
          </Button>
          <Button variant="outlined" onClick={props.onClose}
                  className={classes.spacing}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
      }
    </Dialog>
  )
}

export default ContactDialog;
