import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase';

import { Button, Card, Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginLeft: "5%",
        marginTop: "3%",
        marginBottom: "3%"
    },
    pageHeader: {
        fontSize: "2.5em",
        fontWeight: "bold"
    },
    sectionHeader: {
        fontWeight: "bold",
        fontSize: "1.5em"
    },
    card: {
      padding: "3%",
      marginRight: theme.spacing(6),
    }
}));

const GET_ALL_ADMINS_ENDPOINT = '/getAdminUsers';

export default function Admin(){

    const classes = useStyles();

    const [redirect, setRedirect] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('/');
    const [isAdmin, setIsAdmin] = useState(false);
    const [admins, setAdmins] = useState([]);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                user.getIdTokenResult()
                .then((idTokenResult) => {
                    if (!idTokenResult.claims.admin) {
                        setRedirectUrl("/");
                        setRedirect(true);
                        setIsAdmin(false);
                    } else {
                        setRedirect(false);
                        setIsAdmin(true);
                    }
                })
                .catch((error) => {
                    setRedirectUrl("/");
                    setRedirect(true);
                    setIsAdmin(false);
                });
            }
            else{
                setRedirectUrl("/login");
                setRedirect(true);
            }
          });
    }, []);

    useEffect(() => {
      if (isAdmin) {
        axios.get("/api" + GET_ALL_ADMINS_ENDPOINT)
        .then(res => {
          setAdmins(res.data);
        })
        .catch(e => {
            console.log(e);
        })
      }
    }, [isAdmin]);

    return (
      <>
        {redirect && <Redirect push to={redirectUrl}/>}
        {isAdmin &&
        <Grid container className={classes.root}>
          <Grid container item xs={12} md={6} spacing={7} direction="column">
            <Grid item>
              <Typography className={classes.pageHeader}>
                  Admin Home
              </Typography>
            </Grid>
            <Grid item container direction='column' spacing={2}>
              <Grid item>
                <Typography className={classes.sectionHeader}>Add / Remove Admin</Typography>
              </Grid>
              <Grid item>
                <Typography>Add an already registered user as an admin of the portal OR</Typography>
                <Typography>Remove admin access to the portal for a registered admin user</Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setRedirect(true);
                    setRedirectUrl("/admin/addAdminFromEmail");
                  }}
                >
                  Add / Remove Admin
                </Button>
              </Grid>
            </Grid>
            <Grid item container direction='column' spacing={2}>
              <Grid item>
                <Typography className={classes.sectionHeader}>Add New Team and Primary Contact</Typography>
              </Grid>
              <Grid item>
                <Typography>Add a new team / company and primary contact</Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setRedirect(true);
                    setRedirectUrl("/admin/addTeamAndPrimaryContact");
                  }}
                >
                  Add New Team + Primary Contact
                </Button>
              </Grid>
            </Grid>
            <Grid item container direction='column' spacing={2}>
              <Grid item>
                <Typography className={classes.sectionHeader}>Edit Contact Team and Company</Typography>
              </Grid>
              <Grid item>
                <Typography>Change the company or team of an already existing user</Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setRedirect(true);
                    setRedirectUrl("/admin/editContact");
                  }}
                >
                  Edit Existing Contact
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} md={6} spacing={2} direction="column">
            <Grid item>
              <Typography className={classes.pageHeader}>
                  Current Admins
              </Typography>
            </Grid>

            {admins.map((admin, index) => (
              <Grid item>
              <Card className={classes.card}>
                <Typography className={classes.sectionHeader}>
                  {admin.displayName}
                </Typography>
                <Grid container item>
                  <Grid item xs={2}>
                    <Typography>Email:</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{admin.email}</Typography>
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item xs={2}>
                    <Typography>User ID:</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{admin.uid}</Typography>
                  </Grid>
                </Grid>
              </Card>
              </Grid>
            ))}

          </Grid>
        </Grid>
      }
    </>
  );
}
