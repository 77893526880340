import React, {useState} from 'react';
import axios from 'axios';
import * as validator from "email-validator";
import firebase from "firebase/app";
import "firebase/auth";

import {
    Grid,
    TextField,
    Button,
    Card,
    makeStyles,
    Tooltip,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Typography,
    Paper,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

const RESEND_INVITATION = '/requestInvitation';
const INVITE_TEAM_MEMBER = '/inviteTeamMember';
const REMOVE_TEAM_MEMBER = '/deleteAccount';
const CHANGE_TEAM_PRIMARY = '/changePrimaryContact';

const useStyles = makeStyles((theme: Theme) => ({
    sectionHeader: {
      fontSize: 26,
      marginTop: 20,
      marginBottom: 20,
    },
    sectionSubheader: {
      fontSize: 20,
      fontWeight: 550,
      marginBottom: 20
    },
    divider: {
      background: '#303030',
    },
    memberNames: {
      marginLeft: "5%",
      fontSize: 20,
      fontWeight: 550,
      padding: 12
    },
    addMember: {
      marginLeft: "10%"
    }
}));


export default function AddTeamMembers(props){

    var classes = useStyles();

    const [memberEmail, setMemberEmail] = useState('');
    const [allMembers, setAllMembers] = useState(props.members);
    const [invitedMembers, setInvitedMembers] = useState(props.invitedMembers);
    const [isPrimaryContact, setIsPrimaryContact] = useState(props.isPrimaryContact);
    const [emailError, setEmailError] = useState(null);

    React.useEffect(() => {
      setAllMembers(props.members);
    }, [props.members]);

    React.useEffect(() => {
      setInvitedMembers(props.invitedMembers);
    }, [props.invitedMembers]);

    React.useEffect(() => {
      setIsPrimaryContact(props.isPrimaryContact);
    }, [props.isPrimaryContact]);

    // Resend invitation to already invited team members
    const handleResendInviteClick = (email) => (e) => {
      axios.post('/api' + RESEND_INVITATION, {
        emailId: email,
        senderUid: props.userId
      })
      .then(res => {
        alert("An invitation email has been sent to the requested email: \n" + email);
      })
      .catch(err => {
        if(err.response){
          alert(err.response.data)
        }
      })
    };

    const handleAddMemberClick = (email) => (e) => {
      if (!validator.validate(email)) {
        setEmailError("Please enter a valid email address");
      } else if (invitedMembers.indexOf(email) === -1) {
        firebase.analytics().logEvent('Add Teammate Event: ' + email);
        axios.post('/api' + INVITE_TEAM_MEMBER, {
          emailId: email,
          senderUid: props.userId,
          senderName: props.user.name,
          senderEmail: props.user.email
        })
        .then(res => {
          alert("An invitation email has been sent to the requested email");
          setInvitedMembers((curr) => [...curr, email]);
          setMemberEmail("");
        })
        .catch(err => {
          if(err.response){
            alert(err.response.data)
          }
        })
      }
    }

    const handleAddMemberTextFieldChange = (e) => {
      setMemberEmail(e.target.value);
      setEmailError(null);
    }

    const handleRemoveMember = (index) => {
      if (window.confirm("Are you sure you want to remove the member?")) {
        let removeMemberEmailId = allMembers[index].email;
        axios.post('/api' + REMOVE_TEAM_MEMBER, {
          removeMemberEmailId: removeMemberEmailId,
          requesterUid: props.userId,
        })
        .then(res => {
          setAllMembers((curr) => curr.filter((member) => member.email !== removeMemberEmailId));
        })
        .catch(err => {
          if (err.response) {
            alert(err.response.data)
          }
        })
      }
    }

    const handleChangePrimary = (index) => {
      if (window.confirm("Are you sure you want to make this member the team primary contact?")){
        axios.post('/api' + CHANGE_TEAM_PRIMARY, {
          requesterUid: props.userId,
          newPrimaryEmail: allMembers[index].email
        })
        .then(res => {
          setIsPrimaryContact(false);
          alert(res.data)
        })
        .catch(err => {
          if (err.response) {
            alert(err.response.data)
          }
        })
      }
    }

    return(
      <Grid container spacing={3} direction='column'>
        <Grid item xs={12}>
          <Typography className={classes.sectionHeader}>Team Members</Typography>
          <TableContainer component={Paper} className={classes.container}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Aliases</TableCell>
                  <TableCell>Primary Contact</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allMembers.map((member, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{member.name}</TableCell>
                      <TableCell>{member.email}</TableCell>
                      <TableCell>{member.aliases.join(', ')}</TableCell>
                      <TableCell>
                        {member.isPrimary &&
                         <CheckCircleOutlineIcon style={{color: 'green'}} />
                        }
                      </TableCell>
                      <TableCell>
                        {isPrimaryContact &&
                          <>
                            <Tooltip title="Make Primary Contact">
                              <Button onClick={() => handleChangePrimary(index)}>
                                <LabelImportantIcon />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Remove Team Member">
                              <Button onClick={() => handleRemoveMember(index)}>
                                <ClearIcon style={{color: 'red'}}/>
                              </Button>
                            </Tooltip>
                          </>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
                {isPrimaryContact &&
                  <>
                  {invitedMembers.length > 0 && (
                  <TableRow className={classes.divider}>
                    <TableCell colSpan={5} className={classes.sectionSubheader}>
                      Invited Members
                    </TableCell>
                  </TableRow>
                  )}
                  {invitedMembers.map((email, index) => (
                    <TableRow key={index}>
                      <TableCell></TableCell>
                      <TableCell>{email}</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        {isPrimaryContact &&
                          <Button
                            onClick={handleResendInviteClick(email)}
                            variant='outlined'
                            color='secondary'
                          >
                            Resend Invitation
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                  </>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {isPrimaryContact && (
        <Grid container item direction='column'>
          <Grid item>
            <Typography className={classes.sectionSubheader}>Add Team Member</Typography>
          </Grid>
          <Grid container item alignItems="center" spacing={2}>
              <Grid item xs={12} md={9}>
                  <TextField
                    fullWidth
                    id="member-email"
                    label="Email Address of Team Member"
                    helperText={emailError}
                    variant="outlined"
                    onChange={handleAddMemberTextFieldChange}
                    value={memberEmail}
                    error={emailError !== null}
                  />
              </Grid>
              <Grid item xs={3}>
                  <Button
                    disabled={memberEmail.length === 0}
                    onClick={handleAddMemberClick(memberEmail)}
                    color="primary"
                    variant="outlined"
                    className={classes.addMember}
                  >
                    Add Member
                  </Button>
              </Grid>
          </Grid>
        </Grid>
        )}
      </Grid>
    );
}
