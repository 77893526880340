import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from "firebase/app";
import "firebase/auth";
import axios from 'axios';
import {
  createStyles,
  makeStyles,
  withStyles,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';

import {useParams, Redirect} from 'react-router-dom';

const REQUEST_INVITATION = '/requestInvitation';
const CREATE_NEW_INVITED_USER_ACCOUNT = '/createAccount';

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-root': {
      paddingTop: 12,
      color: '#000',
      fontSize: '18px',
      borderBottom: '2px solid rgba(0,0,0,.12)',
    },
    '& label.MuiInputLabel-root': {
      paddingTop: 6,
      paddingBottom: 16,
      color: 'rgba(0,0,0,.87)',
      fontSize: '18px',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#3f51b5',
      borderWidth: 2
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '75vh',
      flexGrow: 1,
      minWidth: 300,
      backgroundColor: theme.palette.background.paper
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: theme.spacing(2),
    },
    card: {
      border: '2px solid #000',
      backgroundColor: '#FFF',
      margin: theme.spacing(8)
    },
    content: {
      width: 400,
      padding: theme.spacing(1, 2, 1),
    },
    cardHeader: {
      textAlign: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      color: '#000',
      fontWeight: 550,
      fontSize:'1.2rem',
    },
    cardBody: {
      textAlign: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      color: '#000',
      fontSize:'1rem',
    },
    cardActions: {
      marginTop: theme.spacing(3),
      display:'flex',
      justifyContent:"flex-end",
    },
    button: {
      color: '#FFF',
      backgroundColor: '#3f51b5',
      fontSize:'14px',
      fontWeight: 500,
      textTranform: 'uppercase',
      fontFamily: ['Roboto','Helvetica','Arial','sans-serif']
    }
  }),
);

export default function Register(){

    const classes = useStyles();

    const {emailId, senderUid} = useParams();
    const [user, setUser] = React.useState(null);
    const [name, setName] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [validSignUpLink, setValidSignUpLink] = React.useState(null);
    const [redirect, setRedirect] = React.useState(false);
    const [passwordError, setPasswordError] = React.useState(null);
    const [invitationResult, setInvitationResult] = React.useState("");
    const [invitationNextStep, setInvitationNextStep] = React.useState("");

    const handlePassword = (e) => {
      setPassword(e.target.value);
      if (e.target.value.length < 6) {
        setPasswordError("Password length must be atleast 6 characters long");
      } else {
        setPasswordError(null);
      }
    }

    const handleName = (e) => {
      setName(e.target.value);
    }

    /**
     * Request and send a new invitation link by email if current link is invalid or expired
     */
    const handleRequestInvitation = async (e) => {
      try {
        // resend invitation, only if user has already been invited by team
        await axios.post('/api' + REQUEST_INVITATION, {
          senderUid: senderUid,
          emailId: emailId,
        })
        .then(res => {
          setInvitationResult("Invitation Sent.")
          setInvitationNextStep(`To create an account, please use the new invitation link sent to your email: ${emailId}`)
        });
      } catch (error) {
        if (error.response) {
          setInvitationResult("Bad Request");
          setInvitationNextStep(error.response.data);
        }
      }
    }

    /**
     * Create user in firebase database
     * update authenticated user with name and password
     */
    const handleSignUp = async (e) => {
      if (user === null || !emailId) {
        console.log("User undefined / Invalid email");
        return;
      }
      if (password.length < 6) {
        setPasswordError("Password length must be atleast 6 characters long");
        return;
      }
      try {
        // create user in database
        await axios.post('/api' + CREATE_NEW_INVITED_USER_ACCOUNT, {
          senderUid: senderUid,
          inviteEmailId: emailId,
          signInEmailId: emailId,
          InvitedUid: user.uid,
          InvitedName: name
        });

        // update user password and name for email-link authenticated user
        await user.updateProfile({ displayName: name });
        await user.updatePassword(password);
        // reauthenticate user with email and password to get full access to portal
        var credential = firebase.auth.EmailAuthProvider.credential(emailId, password);
        await firebase.auth().signInWithCredential(credential);
      } catch (error) {
        console.log("Account creation error", error);
      }
    }

    /**
     * Sign in if one-time email sign in link is valid
     * Redirect to login page if link is invalid or user not authorized
     */
    React.useEffect(() => {
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        firebase.auth().signInWithEmailLink(emailId, window.location.href)
        .catch((error) => {
          console.log("Failed to sign in with email link", error);
          setValidSignUpLink(false);
        })
      } else {
        setValidSignUpLink(false);
      }
    }, []);

    /**
     * Get changes to user's auth state
     * Redirect to main portal page if user is logged in with email and password
     * Show account sign up link if user is logged in with email sign in link
    */
    React.useEffect(() => {
      firebase.auth().onAuthStateChanged(function(currUser) {
        if (currUser) {
          firebase.auth().fetchSignInMethodsForEmail(currUser.email)
            .then((signInMethods) => {
              if (signInMethods.indexOf(firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) != -1) {
                // Redirect to main portal page when user is logged in with email and password
                setRedirect(true);
              }
              else if (signInMethods.indexOf(firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) != -1) {
                // If verified email sign in link, show account registration page
                setUser(currUser);
                setValidSignUpLink(true);
                firebase.auth().signOut();
              }
            })
        }
      });
    }, []);

    return (
      <>
      {redirect &&
        <Redirect to={"/"}/>
      }
        <Grid container className={classes.root}>
          <Grid container className={classes.container}>
            {invitationResult.length > 0 ? (
              <Card
                aria-labelledby="signup-modal-title"
                className={classes.card}
              >
                <CardContent className={classes.content}>
                  <Typography
                    className={classes.cardHeader}
                  >
                    {invitationResult}
                  </Typography>
                  <Typography
                    className={classes.cardBody}
                  >
                    {invitationNextStep}
                  </Typography>
                </CardContent>
              </Card>
            ) : validSignUpLink === true ? (
              <Card
                aria-labelledby="signup-modal-title"
                className={classes.card}
              >
                <CardContent className={classes.content}>
                  <Typography
                    id="signup-modal-title"
                    variant="h6"
                    className={classes.cardHeader}
                  >
                    Create Account
                  </Typography>
                  <CustomTextField
                    label="Email"
                    type="email"
                    value={emailId}
                    disabled
                    fullWidth
                  />
                  <CustomTextField
                    label="Name"
                    type="text"
                    color="secondary"
                    fullWidth
                    value={name}
                    onChange={handleName}
                  />
                  <CustomTextField
                    label="Password"
                    type="password"
                    fullWidth
                    helperText={passwordError}
                    value={password}
                    onChange={handlePassword}
                    error={passwordError !== null}
                  />
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button
                    variant="contained"
                    onClick={handleSignUp}
                    className={classes.button}
                  >
                    Sign Up
                  </Button>
                </CardActions>
              </Card>
            ) : validSignUpLink === false ? (
              <Card
                aria-labelledby="signup-modal-title"
                className={classes.card}
              >
                <CardContent className={classes.content}>
                  <Typography
                    id="signup-modal-title"
                    variant="h6"
                    className={classes.cardHeader}
                  >
                    The invitation URL is invalid.
                  </Typography>
                  <Typography className={classes.cardBody}>
                    It looks like this invitation link has already been used or is invalid or expired.
                  </Typography>
                  <Typography className={classes.cardBody}>
                    You can request another invite <Link onClick={handleRequestInvitation}>here</Link>.
                  </Typography>
                </CardContent>
              </Card>
            ) : null}
          </Grid>
        </Grid>
      </>
    );
}
