import React from "react";
import axios from 'axios';

import {
    GridList,
    GridListTile,
    GridListTileBar,
    Typography,
    makeStyles,
    Card,
    CardContent,
    Divider,
    Grid,
    Button,
} from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: "5%",
    },
    titles: {
        fontSize: "2em",
        marginLeft: theme.spacing(1),
        fontWeight: "bold",
    },
    labels: {
        fontSize: 22,
        marginTop: "1%",
        fontWeight: "bold",
        marginBottom: "2%"
    },
    gridListTile: {
      border: "1px solid #979797",
    },
    selectedGridListTile: {
      border: "1px solid #979797",
      backgroundColor: "#ED8B3E",
    },
    image: {
      marginTop: "-5%",
      marginLeft: "25%",
      width: "50%",
      height: "50%"
    },
    imageBar: {
      background: 'none'
    },
    imageTitle: {
      fontSize: "1.7vw",
      fontWeight: 600,
      color: "black",
      lineHeight: 1.2,
      textAlign: "center",
      flexWrap: "wrap"
    },
    icon: {
      marginRight: "0.5vw",
      fontSize: "3vw",
      color: "black"
    },
    vl: {
      borderLeft: "1px solid black",
      marginLeft: "50%"
    },
    fileName: {
      fontSize: "1.3em",
      // fontWeight: "bold"
    }
}));

const GET_ORDER_CONFIRMATION_PDF_ENDPOINT = '/file/orderConfirmation/';
const GET_MAILING_LABEL_PDF_ENDPOINT = '/file/mailingLabel/';

export default function OrderOverview(props){

    var orderData = props.data;
    var classes = useStyles();

    // file names and endpoints should be added here for more files
    const files = [
      {
        fileName: 'Order Confirmation',
        endpoint: GET_ORDER_CONFIRMATION_PDF_ENDPOINT
      },
      {
        fileName: 'Mailing Label',
        endpoint: GET_MAILING_LABEL_PDF_ENDPOINT
      }
    ];

    const jobTypes = React.useMemo(() => {
      if (orderData.JobType?.includes("Assembly")) {
        return ["Assembly"];
      } else if (orderData.JobType?.includes("Parts")) {
        return ["Assembly", "Components"];
      } else if (orderData.JobType?.includes("Boards")) {
        return ["Assembly", "PCB"];
      } else if (orderData.JobType?.includes("Full")) {
        return ["Assembly", "Components", "PCB"];
      }
      return [];
    }, [orderData.JobType]);

    const currencyFormat = (num) => {
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    };

    const getFile = (file) => {
      let config = {
        url: '/api' + file.endpoint + orderData.ReferenceNumber,
        method: 'get',
        headers: {
          'content-type':'application/json;charset=UTF-8'
        },
        responseType: 'blob'
      };
      axios(config)
      .then(res => {
        const url = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${file.fileName} - ${orderData.ReferenceNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        console.log(err);
      })
    }

    return (
      <Grid container className={classes.root} direction={'column'} spacing={5}>
        <div hidden>Icons made by <a href="https://www.flaticon.com/authors/surang" title="surang">surang</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
        <Grid item xs={12}>
          <Typography className={classes.titles}>
            Order Overview
          </Typography>

        </Grid>


        {/* Order Type,  Job Type, TotalCost */}
        <Grid item xs={12}>
          <Typography style={{margin: 5}}>
            The below graphic details which services you have ordered.
          </Typography>
          <Card>
            <CardContent>
              <GridList cols={3}>
                <GridListTile key={"Assembly"} cols={1} className={jobTypes.includes("Assembly") ? classes.selectedGridListTile : classes.gridListTile}>
                  <img src={process.env.PUBLIC_URL + '/assembly-line.svg'} alt={"Assembly"} className={classes.image} />
                  <GridListTileBar
                    title={"Assembly"}
                    classes={{
                      root: classes.imageBar,
                      title: classes.imageTitle
                    }}
                    actionIcon={
                      jobTypes.includes("Assembly")
                      ? <CheckCircleOutlineIcon className={classes.icon} />
                      : null
                    }
                  />
                </GridListTile>
                <GridListTile key={"Components"} cols={1} className={jobTypes.includes("Components") ? classes.selectedGridListTile : classes.gridListTile}>
                  <img src={process.env.PUBLIC_URL + '/processor.svg'} alt={"Components"} className={classes.image} />
                  <GridListTileBar
                    title={"Components"}
                    classes={{
                      root: classes.imageBar,
                      title: classes.imageTitle
                    }}
                    actionIcon={
                      jobTypes.includes("Components")
                      ? <CheckCircleOutlineIcon className={classes.icon}  />
                      : null
                    }
                  />
                </GridListTile>
                <GridListTile key={"PCB"} cols={1} className={jobTypes.includes("PCB") ? classes.selectedGridListTile : classes.gridListTile}>
                  <img src={process.env.PUBLIC_URL + '/pcb.svg'} alt={"PCB"} className={classes.image} />
                  <GridListTileBar
                    title={"PCB"}
                    classes={{
                      root: classes.imageBar,
                      title: classes.imageTitle
                    }}
                    actionIcon={
                      jobTypes.includes("PCB")
                      ? <CheckCircleOutlineIcon className={classes.icon} />
                      : null
                    }
                  />
                </GridListTile>
              </GridList>
            </CardContent>
          </Card>
        </Grid>
        {/* Order Type,  Estimated TotalCost */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={5}>
                    <Grid item xs={12} className={classes.labels}>
                      Order Type
                    </Grid>
                    <Grid item xs={12}>
                      {orderData.OrderType ? orderData.OrderType : "-"}
                    </Grid>
                </Grid>

                <Grid item sm={1}>
                  <Divider orientation="vertical" className={classes.vl} display={{ xs: 'none', sm: 'block' }} />
                </Grid>

                <Grid item xs={12} sm={5}>
                    <Grid item xs={12} className={classes.labels}>
                      {orderData.OrderStatus != "Completed" && "Estimated"} Total Cost
                    </Grid>
                    <Grid item xs={12}>
                      {currencyFormat(orderData.TotalCost)}
                    </Grid>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Shipping address */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography className={classes.labels}>
                Shipping Address
              </Typography>
              {
                orderData.ShippingAddress.split("\n").map((line) => {
                  return (
                    <Typography>
                      {line}
                    </Typography>
                  )
                })
              }
            </CardContent>
          </Card>
        </Grid>

        {orderData.SalesRep.length > 0 && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography className={classes.labels}>
                Sales Representative
              </Typography>
              <Typography>
                {orderData.SalesRep}
              </Typography>
              <br/>
              <Typography>
                For any issues, please contact client services
                at <a href="mailto:clientservices@aapcb.com">
                  clientservices@aapcb.com
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        )}

        <Grid item xs={12}>
        <Card>
            <CardContent>
              <Typography className={classes.labels}>
                Files
              </Typography>
              <Grid contianer direction='column'>
              {
                files.map(file => {
                  return(
                    <Grid item container style={{marginBottom: '3%'}}>
                      <Grid item xs={6}>
                        <Typography className={classes.fileName}>{file.fileName}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Button onClick={() => getFile(file)} color='secondary' variant='outlined'>Download</Button>
                      </Grid>
                    </Grid>
                  );
                })
              }
              </Grid>
              
            </CardContent>
          </Card>
        </Grid>

      </Grid>
    );
}
