/* Loading.tsx
 * This component renders a spinning circle to indicate something is loading.
 */

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles/";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(10),
  },
}));


function Loading(props: any) {
  const classes = useStyles();

  return (
    <Grid container direction="column" alignItems="center" spacing={3} className={classes.root}>
      <Grid item>
        <CircularProgress variant="indeterminate" disableShrink={true} />
      </Grid>
      <Grid item>
        <Typography variant="h6">{props.text ?? "Loading..."}</Typography>
      </Grid>
    </Grid>
  );
}

export default Loading;
