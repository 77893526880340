import React, {useState, useEffect, useMemo} from "react";
import axios from 'axios';
import {useParams, withRouter, Redirect, useLocation } from 'react-router-dom';
import firebase from 'firebase/app';
import "firebase/auth";
// import FileDownload from "js-file-download";
import {
    Grid,
    Divider,
    makeStyles,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination,
    Theme,
    TextField,
    Typography,
    Chip,
} from "@material-ui/core";

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Lottie from 'react-lottie';
import animationData from "./lottie-circuit-light.json";
import animationDataDark from "./lottie-circuit-dark.json";
import { useTheme } from '@material-ui/core/styles';
import OrderTimeLine from './OrderTimeLine';
import DeliveryTimeLine from './DeliveryTimeLine';
import OrderTable from '../../common/OrderTable';
import OrderOverview from '../../common/OrderOverview';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
    },
    body: {
      width: "80%",
      marginLeft: "10%",
      margin: theme.spacing(3),
      minHeight: "100vh",
    },
    searching: {
      width: "80%",
      marginLeft: "10%",
      height: "100vh",
    },
    footer: {
      margin: theme.spacing(1),
      marginLeft: "auto",
    },
    note: {
      margin: theme.spacing(1),
      fontStyle: 'italic',
    },
    spacing: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    customer: {
      paddingBottom: theme.spacing(3),
      marginBottom: theme.spacing(3),
      borderBottom: "solid 0.2rem"
    },
    customerName: {
      marginBottom: theme.spacing(1),
      fontSize: "1.6rem",
      fontWeight: 550,
    }
}));


// Main OrderStatus Component that calls other related components like TimeLine
function CustomerStatus(props){

    const GET_CUSTOMER_STATUS_ENDPOINT = "/ordersByCustomer";
    const GET_SALES_REP_ORDERS_ENDPOINT = "/ordersBySalesRep";

    const { uniqueCustomerId } = useParams();
    // state variables
    const [isAdmin, setIsAdmin] = useState(false);
    const [customer, setCustomer] = useState("");
    const [orderData, setOrderData]= useState([]);
    const [filteredOrderData, setFilteredOrderData] = useState([]);

    const [search, setSearch] = useState("");
    const [customerSearch, setCustomerSearch] = useState(undefined);
    const [customerError, setCustomerError] = useState(false);
    const [pageState, setPageState] = useState(0);
    const [page, setPage] = useState(0);

    const [redirect, setRedirect] = useState(false);
    const [currUrl, setCurrUrl] = useState(useLocation().pathname);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [customerId, setCustomerId] = useState((props.companyId !== undefined) ? props.companyId : uniqueCustomerId);

    const classes = useStyles();
    const theme = useTheme();

    const formatTime = (timestamp) => {
      return moment(timestamp).format("MMM D, YYYY");
    }

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: theme.palette.type === 'dark' ?
                     animationDataDark : animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleSetState = (data) => {
      setCustomer(data.company);
      setOrderData(data.orders);
      setFilteredOrderData(data.orders);
      setPageState(1);
    }

    // backend call to get order-status details
    const getOrderData = (user) => {
      axios.post("/api" + GET_CUSTOMER_STATUS_ENDPOINT, {
        isAdmin: isAdmin,
        uid: user?.uid,
        customer: customerSearch
      })
      .then((res) => {
        if (res.status === 204) {
          setPageState(1);
          setCustomerError(true);
        } else {
          handleSetState(res.data);
        }
      })
      .catch((err) => {
        setPageState(2);
        console.error(err);
      })
    }

    const getSalesRepOrders = (user) => {
      axios.post("/api" + GET_SALES_REP_ORDERS_ENDPOINT, {
        email: user.email,
      })
      .then((res) => {
        console.log(res.data);
        handleSetState(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
    }

    const handleSearch = React.useCallback((value) => (e) => {
      if (search === value) {
        setSearch("");
      } else {
        setSearch(value);
      }
    }, [search]);

    // below 'useEffect' runs only when the component is mounted for the first time, not on component updates. Remove '[]' to run everytime...
    useEffect(() => {
      document.title = 'Customer Overview';
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            firebase.analytics().logEvent('Customer Overview', {email: user.email});
            user.getIdTokenResult()
            .then((idTokenResult) => {
                if (!idTokenResult.claims.admin) {
                  setPageState(0);
                  getOrderData(user);
                } else {
                  setIsAdmin(true);
                  setPageState(1);
                  getSalesRepOrders(user);
                }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    }, []);

    useEffect(() => {
      if (search === "") {
        setFilteredOrderData(orderData);
      }
      let lSearch = search.toLowerCase();
      let filtered = orderData.filter((order) => {
        return (
          order.ReferenceNumber.toLowerCase().includes(lSearch) ||
          order.ProjectName?.toLowerCase().includes(lSearch) ||
          order.OrderStatus.toLowerCase().includes(lSearch)
        );
      });
      setFilteredOrderData(filtered);
    }, [search]);

    const orderTableData = useMemo(() => {
      return filteredOrderData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [filteredOrderData, page, rowsPerPage]);

    return(
      <div className={classes.body}>
      {pageState === 0 && // loading state
        <Grid container justify="center" className={classes.searching}>
          <Grid item>
            <Lottie options={defaultOptions}
              height={400}
              width={400}
            />
          <h2 style={{textAlign: "center"}}>Loading...</h2>
          </Grid>
        </Grid>
      }
      { pageState === 1 && // data received
        <Grid className={classes.root}>
          {isAdmin && // admin search
            <TextField
              fullWidth
              variant="outlined"
              label="Customer Name"
              className={classes.customer}
              value={customerSearch}
              onChange={(e) => {
                setCustomerSearch(e.target.value);
                setCustomerError(false);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") getOrderData();
              }}
              helperText={customerError ? "Invalid Customer Name" : ""}
              error={customerError}
            />
          }
          <Typography className={classes.customerName}>{customer}</Typography>
          <div className={classes.note}>
            This page contains all orders placed by customer. Use
            the details button to see the full order status page for any given
            order.
          </div>
          <TextField
            fullWidth
            variant="outlined"
            label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            helperText="Filter by Order #, Part #, or Status"
          />
          <Grid container spacing={1} className={classes.spacing} justify="flex-start">
            <Grid item>
              <Chip
                variant={search === "Processing" ? "default" : "outlined"}
                label="Processing"
                color="secondary"
                onClick={() => setSearch("Processing")}
              />
            </Grid>
            <Grid item>
              <Chip
                variant={search === "In Prod" ? "default" : "outlined"}
                label="Production"
                color="secondary"
                onClick={() => setSearch("In Prod")}
              />
            </Grid>
            <Grid item>
              <Chip
                variant={search === "Shipped" ? "default" : "outlined"}
                label="Shipped"
                color="secondary"
                onClick={() => setSearch("Shipped")}
              />
            </Grid>

            {/*<Grid item xs={6} md={3}>
              <Chip
                variant={search === "On Hold" ? "default" : "outlined"}
                label="On Hold"
                color="primary"
                onClick={() => setSearch("On Hold")}
              />
            </Grid>*/}
          </Grid>
          <Divider />
          <Grid container>
            <Grid item xs={12}>
            <OrderTable data={orderTableData} />
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={filteredOrderData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              className={classes.footer}
            />
            </Grid>
            <div className={classes.footer}>{filteredOrderData.length} results found</div>
          </Grid>
        </Grid>
      }
      {pageState === 2 && // invalid uniqueOrderId
      <Grid container justify="center" className={classes.searching}>
        <h2 style={{textAlign: "center"}}>
          We couldn't find a customer with the provided unique id {uniqueCustomerId}.
          Please try again, or send an email to support@aapcb.com to get
          the correct ID.
        </h2>
      </Grid>
      }
      </div>
    );
}

export default withRouter(CustomerStatus);
