import React, {useState, useEffect} from 'react'

import {
    Grid,
    Typography,
    makeStyles,
    Card
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "80%",
        marginLeft: "10%",
        textAlign: "center",
        height: "80vh",
    },
    error: {
        fontSize: "4em",
        fontWeight: "bold"
    },
    message: {
        fontWeight: "bold",
        fontSize: "1.5em"
    },
    card: {
        padding: "5%",
        marginTop: "10%",
        boxShadow: `0 4px 8px 0 ${theme.palette.secondary.main}, 0 6px 20px 0 ${theme.palette.secondary.main}`
    }
}));

export default function ErrorPage(props){
    const classes = useStyles()

    const [code, setCode] = useState();
    const [message, setMessage] = useState("");

    useEffect(() => {
        if(props){
            setCode(props.errorCode)
            setMessage(props.message)
        }
    }, [props]);

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <Grid container alignItems="center" direction="column" spacing={3}>
                    <Grid item>
                        <Typography className={classes.error} color="secondary">{code}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.message}>{message}</Typography>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
}
