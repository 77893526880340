import React from 'react';

import {
    AppBar,
    Box,
    Grid,
    makeStyles,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Card,
    CardContent,
    Tab,
    Tabs,
    Typography,
} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/Check';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import moment from "moment";

function tabProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
    width: "100%",
  },
  deliveryTimeLine: {
    marginTop:"1%",
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  deliveryDate: {
    fontSize: 18,
    margin: theme.spacing(1),
  },
  titles: {
    marginTop: "3%",
    fontSize: "2em",
    fontWeight: "bold",
  },
  labels: {
    fontSize: '1rem',
    fontWeight: "bold",
    padding: "6px",
  },
  timeline: {
    marginTop: "3%",
    maxHeight: 300,
    overflowY: "scroll",
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

export default function DeliveryTimeLine(props) {
    console.log(props);
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
    };

    const formatTime = (timestamp) => {
      return moment(timestamp).format("MMM D, YYYY");
    };

    const CheckStepIcon = React.useCallback(() => {
      return (<CheckCircleIcon style={{ fill: 'green' }} />);
    }, []);

    // Shipment steps
    const steps = React.useMemo(() => {
      return props.shipments[value]?.TrackingInfo?.tracking_details?.slice(0).reverse();
    }, [value]);

    const trackingInfo = React.useMemo(() => {
      return props.shipments[value] ? props.shipments[value].TrackingInfo : null;
    }, [value]);

    const quantity = React.useMemo(() => {
      return props.shipments[value]?.Quantity;
    }, [value]);

    const arrivalDate = React.useMemo(() => {
      return trackingInfo?.status === "delivered"
        ? formatTime(steps[0].datetime.split("T")[0])
        : trackingInfo?.est_delivery_date?.length > 0
          ? formatTime(trackingInfo.est_delivery_date.split("T")[0])
          : "";
    }, [steps, trackingInfo, value]);

    return props.showTrackingDetails ? (
    <Grid item xs={12} className={classes.root}>
      <Grid item xs={12} className={classes.titles}>
        Shipment Details
      </Grid>
      <div className={classes.deliveryTimeLine}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {props.shipments.map((_, index) => (
            <Tab key={index} label={"Shipment " + (index+1)} icon={props.shipments[index].TrackingInfo?.status=== "delivered" ? <CheckCircleOutlineIcon style={{ color: 'green' }}/> : null} {...tabProps(index)} />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={value}>
        <Card className={classes.root}>
          <CardContent>
            {arrivalDate.length > 0
              ? (trackingInfo?.status) === "delivered" ? (
                <Typography className={classes.labels}>Delivered on: {arrivalDate}</Typography>
              ) : (
                <Typography className={classes.labels}>Estimated Arrival Date: {arrivalDate}</Typography>
              ) : null}
            <Grid container>
              {trackingInfo && (
                <Grid item xs={12} sm={8} md={6} lg={6}>
                  <Typography className={classes.labels}>Shipped via {trackingInfo.carrier}</Typography>
                  <Typography className={classes.labels}>Tracking: {trackingInfo.tracking_code}</Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={8} md={6} lg={6}>
                <Typography className={classes.labels}>Shipped On: {formatTime(props.shipments[value].ShipmentTimeStamp * 1000)}</Typography>
                <Typography className={classes.labels}>Quantity Shipped: {quantity}</Typography>
              </Grid>
            </Grid>
            <Stepper className={classes.timeline} activeStep={-1} orientation="vertical">
              {steps && steps.map((step, index) => (
                <Step key={index} expanded={true}>
                  <StepLabel StepIconComponent={CheckStepIcon}>
                    {step.datetime.split("T")[0]}
                  </StepLabel>
                  <StepContent>
                    <Typography>{step.message}</Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </CardContent>
        </Card>
      </TabPanel>
    </div>
    </Grid>
    ) : (
      <Grid item xs={12} className={classes.root} >
        <Grid item xs={12} className={classes.titles}>
          Estimated Ship Date
        </Grid>
        <Grid item xs={6} className={classes.deliveryDate}>
          {formatTime(props.shipDate)}
        </Grid>
      </Grid>
    );
  }
