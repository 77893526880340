/* QuoteView.tsx
 * This file renders the main dashboard for BomBuilder. When the website is
 * opened to the index endpoint (/), this is rendered. It shows a dashboard
 * of the latest 20 jobs, as well as the ability to search for a past job,
 * or create a new job.
 */

import React, { forwardRef, useRef, useImperativeHandle } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography
} from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from "notistack";
import Loading from "../../common/Loading";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CustomQuoteCard from "../../common/CustomQuoteCard";

import QuoteTimeLine from './QuoteTimeLine';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 600,
    margin: theme.spacing(1),
    marginLeft: "5vw",
    marginRight: "10vw"
  },
  close: {
    padding: theme.spacing(0.5),
  },
  tableRows: {
    cursor: "pointer",
  },
  dashboardHeader: {
    fontSize: 35,
    fontWeight: "bold",
  },
  header: {
    marginBottom: 50,
  },
  pageHeader: {
    fontSize: 32,
    fontWeight: "bold",
    paddingBottom: 10,
  },
  sectionHeader: {
    fontSize: 24,
    fontWeight: "bold",
    paddingTop: 25,
    paddingBottom: 10,
  },
  fieldMargins: {
    margin: theme.spacing(1),
  },
}));

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  name: yup
    .string('Enter your full name')
    .required('Name is required'),
  projName: yup
    .string('Enter your project name')
    .required('Project Name is required'),
  boardWidth: yup
    .number('Enter your board width')
    .required('Board Width is required')
    .positive(),
  boardLength: yup
    .number('Enter your board length')
    .required('Board Length is required')
    .positive(),
  boardThickness: yup
    .number('Enter your board thickness')
    .required('Board Thickness is required')
    .positive(),
  layers: yup
    .number('Enter your number of layers')
    .required('# Layers is required')
    .moreThan(0, 'Must have at least 1 layer')
    .integer(),
  material: yup
    .string('Enter your material')
    .required('Material is required'),
  surfaceFinish: yup
    .string('Enter your surface finish')
    .required('Surface Finish is required'),
  innerCopper: yup
    .number('Enter your inner copper weight')
    .required('Inner Copper Weight is required')
    .positive(),
  outerCopper: yup
    .number('Enter your outer copper weight')
    .required('Outer Copper Weight is required')
    .positive(),
});

function QuoteView() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [step, setStep] = React.useState(0);
  const [formInput, setFormInput] = React.useState();

  const childRef = useRef();

  const submitForm = (values) => {
    enqueueSnackbar("Form submitted successfully!", {variant: 'success'});
    setFormInput(values);
  }

  const handleNext = () => {
    if(step === 0){
      childRef.current.getData();
    }
    setStep((prevStep) => prevStep + 1);
  }

  return (
    <Grid className={classes.root}>
      <Grid container className={classes.header}>
        <Grid item>
          <Typography className={classes.dashboardHeader}>
            Quote A Project
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <QuoteTimeLine step={step} />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9} lg={9}>
          { step === 0 ?
            <QuoteForm submitForm={submitForm} ref={childRef}/> :
            <QuotePricing data={formInput}/>
          }
          <Grid container justify="space-between" style={{marginTop: 10}}>
            <Grid item>
              <Button
                variant="contained"
                disabled={step === 0}
                onClick={() => setStep((prevStep) => prevStep - 1)}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                disabled={step === 4}
                onClick={handleNext}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <CustomQuoteCard />
        </Grid>
      </Grid>
    </Grid>
  );
}

const QuoteForm = forwardRef((props, ref) => {

  const submitForm = props.submitForm;
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      email: 'testemail@gmail.com',
      name: 'Mehul Shah',
      company: 'Apple',
      region: 'CA',
      projName: 'test proj v1',
      projRev: '',
      boardWidth: '2',
      boardLength: '2',
      boardThickness: 0.062,
      layers: '2',
      material: 'FR4',
      surfaceFinish: 'ENIG',
      innerCopper: '1',
      outerCopper: '1',
      mask: 'green',
      silk: 'white',
      itar: 'false',
      spec: '2',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitForm(values);
    },
  });

  useImperativeHandle(ref, () => ({

    getData() {
      formik.handleSubmit();
    }

  }));

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item>
          <Typography className={classes.pageHeader}>
            Project Basics
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Typography className={classes.sectionHeader}>
            Customer Information
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <TextField
            id="name"
            name="name"
            label="Full Name *"
            variant="outlined"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField
            id="email"
            name="email"
            label="Email *"
            variant="outlined"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField
            id="company"
            name="company"
            label="Company"
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <TextField
            id="region"
            name="region"
            label="State / Region"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField
            id="projName"
            name="projName"
            label="Project Name *"
            variant="outlined"
            value={formik.values.projName}
            onChange={formik.handleChange}
            error={formik.touched.projName && Boolean(formik.errors.projName)}
            helperText={formik.touched.projName && formik.errors.projName}
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField
            id="projRev"
            name="projRev"
            label="Project Revision"
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Typography className={classes.sectionHeader}>
            Board Specs
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <TextField
            id="boardWidth"
            name="boardWidth"
            label="Board Width *"
            variant="outlined"
            value={formik.values.boardWidth}
            onChange={formik.handleChange}
            error={formik.touched.boardWidth && Boolean(formik.errors.boardWidth)}
            helperText={formik.touched.boardWidth && formik.errors.boardWidth}
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField
            id="boardLength"
            name="boardLength"
            label="Board Length *"
            variant="outlined"
            value={formik.values.boardLength}
            onChange={formik.handleChange}
            error={formik.touched.boardLength && Boolean(formik.errors.boardLength)}
            helperText={formik.touched.boardLength && formik.errors.boardLength}
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField
            id="boardThickness"
            name="boardThickness"
            label="Board Thickness *"
            variant="outlined"
            value={formik.values.boardThickness}
            onChange={formik.handleChange}
            error={formik.touched.boardThickness && Boolean(formik.errors.boardThickness)}
            helperText={formik.touched.boardThickness && formik.errors.boardThickness}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <TextField
            id="layers"
            name="layers"
            label="Layers *"
            variant="outlined"
            value={formik.values.layers}
            onChange={formik.handleChange}
            error={formik.touched.layers && Boolean(formik.errors.layers)}
            helperText={formik.touched.layers && formik.errors.layers}
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField
            id="material"
            name="material"
            label="Material *"
            variant="outlined"
            value={formik.values.material}
            onChange={formik.handleChange}
            error={formik.touched.material && Boolean(formik.errors.material)}
            helperText={formik.touched.material && formik.errors.material}
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField
            id="surfaceFinish"
            name="surfaceFinish"
            label="Surface Finish *"
            variant="outlined"
            value={formik.values.surfaceFinish}
            onChange={formik.handleChange}
            error={formik.touched.surfaceFinish && Boolean(formik.errors.surfaceFinish)}
            helperText={formik.touched.surfaceFinish && formik.errors.surfaceFinish}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextField
            id="innerCopper"
            name="innerCopper"
            label="Inner Copper Weight *"
            variant="outlined"
            value={formik.values.innerCopper}
            onChange={formik.handleChange}
            error={formik.touched.innerCopper && Boolean(formik.errors.innerCopper)}
            helperText={formik.touched.innerCopper && formik.errors.innerCopper}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outerCopper"
            name="outerCopper"
            label="Outer Copper Weight *"
            variant="outlined"
            value={formik.values.outerCopper}
            onChange={formik.handleChange}
            error={formik.touched.outerCopper && Boolean(formik.errors.outerCopper)}
            helperText={formik.touched.outerCopper && formik.errors.outerCopper}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Typography className={classes.sectionHeader}>
            Board Appearance
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormControl>
            { formik.values.mask !== 'green' && (
              <Chip
                size="small"
                icon={<AttachMoneyIcon style={{color: 'black'}}/>}
                style={{backgroundColor: '#85bb65', width: 100, marginBottom: 8}}
                label={"+50"}
              />
            )}
            <FormLabel>Soldermask Color</FormLabel>
            <Select
              id="mask"
              name="mask"
              label="Soldermask Color"
              value={formik.values.mask}
              onChange={formik.handleChange}
            >
              <MenuItem value="green">Green</MenuItem>
              <MenuItem value="black">Black</MenuItem>
              <MenuItem value="clear">Clear</MenuItem>
              <MenuItem value="red">Red</MenuItem>
            </Select>
            <FormHelperText>
              Non-green soldermask will incur a $50 surcharge.
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl>
            { formik.values.silk !== 'white' && (
              <Chip
                size="small"
                icon={<AttachMoneyIcon style={{color: 'black'}}/>}
                style={{backgroundColor: '#85bb65', width: 100, marginBottom: 8}}
                label={"+50"}
              />
            )}
            <FormLabel>Silkscreen Color</FormLabel>
              <Select
                id="silk"
                name="silk"
                label="Silkscreen Color"
                value={formik.values.silk}
                onChange={formik.handleChange}
              >
              <MenuItem value="white">White</MenuItem>
              <MenuItem value="black">Black</MenuItem>
              <MenuItem value="clear">Clear</MenuItem>
            </Select>
            <FormHelperText>
              Non-white silkscreen will incur a $50 surcharge.
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Typography className={classes.sectionHeader}>
            Custom Options
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormControl>
            { formik.values.itar === 'true' && (
              <Chip
                size="small"
                icon={<AttachMoneyIcon style={{color: 'black'}}/>}
                style={{backgroundColor: '#85bb65', width: 100, marginBottom: 8}}
                label={"+300"}
              />
            )}
            <FormLabel>ITAR *</FormLabel>
            <RadioGroup
              row
              id="itar"
              name="itar"
              label="ITAR"
              value={formik.values.itar}
              onChange={formik.handleChange}
            >
              <FormControlLabel value={"false"} control={<Radio />} label="No" />
              <FormControlLabel value={"true"} control={<Radio />} label="Yes" />
            </RadioGroup>
            <FormHelperText>
              Our site and facility is fully ITAR-compliant. A $300 surcharge
              will be added for ITAR orders.
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl>
            { formik.values.spec === '3' && (
              <Chip
                size="small"
                icon={<AttachMoneyIcon style={{color: 'black'}}/>}
                style={{backgroundColor: '#85bb65', width: 100, marginBottom: 8}}
                label={"+500"}
              />
            )}
            <FormLabel>Specification *</FormLabel>
              <RadioGroup
                row
                id="spec"
                name="spec"
                label="Specification"
                value={formik.values.spec}
                onChange={formik.handleChange}
              >
              <FormControlLabel value="2" control={<Radio />} label="IPC Class 2" />
              <FormControlLabel value="3" control={<Radio />} label="IPC Class 3" />
            </RadioGroup>
            <FormHelperText>
              A $500 surcharge will be added for all IPC Class 3 Projects.
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
})

function QuotePricing({ data }) {
  const classes = useStyles();

  const [quantity1, setQuantity1] = React.useState(1);
  const [quantity2, setQuantity2] = React.useState(5);
  const [quantity3, setQuantity3] = React.useState(10);
  const [quantity4, setQuantity4] = React.useState(25);

  const buildTable = () => {
    const leadTimes = [1, 3, 5, 10];
    return (
      <React.Fragment>
        <TableRow>
          <TableCell component="th" scope="row">
            <TextField
              size="small"
              value={quantity1}
              onChange={ev=>setQuantity1(ev.target.value)}
            />
          </TableCell>
          {
            leadTimes.map((lt, index) => {
              let price = calculatePricing(quantity1, lt);
              return (
                <TableCell key={price + index}>{price}</TableCell>
              );
            })
          }
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            <TextField
              size="small"
              value={quantity2}
              onChange={ev=>setQuantity2(ev.target.value)}
            />
          </TableCell>
          {
            leadTimes.map((lt, index) => {
              let price = calculatePricing(quantity2, lt);
              return (
                <TableCell
                  key={price + index}
                  onClick={(e) => e.target.style.backgroundColor = '#B99858'}
                >
                  {price}
                </TableCell>
              );
            })
          }
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            <TextField
              size="small"
              value={quantity3}
              onChange={ev=>setQuantity3(ev.target.value)}
            />
          </TableCell>
          {
            leadTimes.map((lt, index) => {
              let price = calculatePricing(quantity3, lt);
              return (
                <TableCell key={price + index}>{price}</TableCell>
              );
            })
          }
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            <TextField
              size="small"
              value={quantity4}
              onChange={ev=>setQuantity4(ev.target.value)}
            />
          </TableCell>
          {
            leadTimes.map((lt, index) => {
              let price = calculatePricing(quantity4, lt);
              return (
                <TableCell key={price + index}>{price}</TableCell>
              );
            })
          }
        </TableRow>
      </React.Fragment>
    );
  };

  const calculatePricing = (quantity, leadtime)=> {
    let dimensions = parseFloat(data.boardWidth) * parseFloat(data.boardLength);
    let totalPrice = (dimensions * quantity * 5 * leadtime).toFixed(2);
    return '$' + totalPrice;
  };

  if (!data) {
    return (
      <Loading />
    )
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item>
          <Typography className={classes.pageHeader}>
            Pricing Options
          </Typography>
          <Typography paragraph>
            Select an option in the table below. Adjust the quantity and leadtime
            fields to get updated pricing.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Quantity</TableCell>
                <TableCell>1 Day</TableCell>
                <TableCell>3 Days</TableCell>
                <TableCell>5 Days</TableCell>
                <TableCell>10 Days</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableRows}>{buildTable()}</TableBody>
          </Table>
        </TableContainer>
      </Grid>

    </React.Fragment>
  )
}

export default QuoteView;
