import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import{
    TextField,
    Typography,
    Button,
    Grid,
    Switch,
    makeStyles
} from '@material-ui/core';
import axios from 'axios';
import firebase from 'firebase';
import * as validator from "email-validator";

const ADD_ADMIN_FROM_EMAIL_ENDPOINT = '/addAdminFromEmail';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
        marginLeft: "5%",
        marginTop: "3%",
        height: "80vh"
    },
    pageHeader: {
        fontSize: "2.5em",
        fontWeight: "bold"
    },
    sectionHeader: {
        fontWeight: "bold",
        fontSize: "1.5em"
    }
}));

export default function AddAdminFromEmail(){

    const classes = useStyles();

    const [isAdmin, setIsAdmin] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState("/");
    const [email, setEmail] = useState('');
    const [addAdmin, setAddAdmin] = useState(true);
    const [emailError, setEmailError] = useState(null);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
              user.getIdTokenResult()
              .then((idTokenResult) => {
                  if (!idTokenResult.claims.admin) {
                      setRedirectUrl("/");
                      setIsAdmin(false);
                      setRedirect(true);
                  } else {
                      setIsAdmin(true);
                      setRedirect(false);
                  }
              })
              .catch((error) => {
                  setRedirectUrl("/");
                  setIsAdmin(false);
                  setRedirect(true);
              });
          }
          else{
            setRedirectUrl("/login");
            setIsAdmin(false);
            setRedirect(true);
          }
          });
    }, [])

    const handleChangeEmail = e => {
      setEmail(e.target.value);
      setEmailError(null);
    }

    const handleEmailSubmit = () => {
      if (!validator.validate(email)) {
        setEmailError("Please enter a valid email address");
      } else {
        axios.post("/api" + ADD_ADMIN_FROM_EMAIL_ENDPOINT, {
            email: email,
            addAdmin: addAdmin
        })
        .then(res => {
            alert(res.data);
            setEmail("");
        })
        .catch(e => {
            console.log(e);
            alert(e.response.data);
        })
      }
    }

    return(
      <>
      {redirect && <Redirect push to={redirectUrl}/>}
      {isAdmin &&
      <Grid container className={classes.root} spacing={7} direction="column">
        <Grid container item>
          <Typography className={classes.pageHeader}>
            Add / Remove Admin by Email
          </Typography>
        </Grid>
        <Grid container item>
          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>Delete Admin</Grid>
              <Grid item>
                <Switch
                  checked={addAdmin}
                  onChange={(e) => setAddAdmin(e.target.checked)}
                  name="addAdmin"
                />
              </Grid>
              <Grid item>Add Admin</Grid>
            </Grid>
          </Typography>
        </Grid>
        <Grid container item>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={7}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      helperText={emailError}
                      error={emailError !== null}
                      onChange={handleChangeEmail}
                      value={email}
                      label={`Enter email to ${addAdmin ? 'add' : 'remove'} admin`}
                    />
                </Grid>
                <Grid item xs={7}>
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={email.length === 0}
                      onClick={handleEmailSubmit}
                    >
                      {addAdmin ? 'Add' : 'Remove'} Admin
                    </Button>
                </Grid>
            </Grid>
        </Grid>
      </Grid>
    }
    </>
    );
}
