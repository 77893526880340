import React, {useEffect, useState, useMemo} from 'react';
import {Redirect} from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import moment from "moment";
import CustomerStatus from '../components/OrderStatus/CustomerStatus';
import ErrorPage from './ErrorPage';
import OrderTimeLine from '../components/OrderStatus/OrderTimeLine';
import MobileOrderTimeLine from '../components/OrderStatus/MobileOrderTimeLine';
import OrderTable from '../common/OrderTable';
import Loading from '../common/Loading';

import {
    Grid,
    makeStyles,
    Theme,
    Typography,
    Button,
    useMediaQuery,
    CircularProgress
} from '@material-ui/core';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      marginTop: "3%",
      marginBottom: "3%"
    },
    subHeader: {
      marginTop: 20,
      fontWeight: 600,
      fontSize: 28,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    orderTimeline: {
      borderRadius: "30px"
    }
}));

export default function Home(){

    const GET_CUSTOMER_STATUS_ENDPOINT = "/ordersByCustomer";
    const LIMIT = 5;

    const classes = useStyles();
    const isMobile = !useMediaQuery('(min-width:600px)');

    const [redirect, setRedirect] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState("/");
    const [pageState, setPageState] = useState(0);
    const [company, setCompany] = useState("");
    const [ordersData, setOrdersData] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    // backend call to get order-status details
    const getOrderData = (user) => {
      axios.post("/api" + GET_CUSTOMER_STATUS_ENDPOINT, {
        uid: user.uid,
        limit: LIMIT
      })
      .then((res) => {
          setCompany(res.data.company);
          setOrdersData(res.data.orders);
          setPageState(1);
      })
      .catch((err) => {
        setPageState(2);
        console.error(err);
      })
    }

    const getRecentOrders = (user) => {
      axios.post("api/recentOrders", {
        uid: user.uid,
        limit: LIMIT
      })
      .then((res) => {
        setCompany(res.data.company);
        setOrdersData(res.data.orders);
        setPageState(1);
      })
      .catch((err) => {
        setPageState(2);
        console.error(err);
      })
    }

    useEffect(() => {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult()
            .then((idTokenResult) => {
                if (!idTokenResult.claims.admin) {
                  setPageState(0);
                  getOrderData(user);
                } else {
                  setIsAdmin(true);
                  getRecentOrders(user);
                }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    }, []);

    const mostRecentOrderStatus = useMemo(() => {
        if(ordersData === undefined || ordersData === null || ordersData.length === 0){
            return "";
        }
        var data = ordersData[0];
        if (data.OrderStatus === "Shipped" && data.Shipments !== undefined && data.Shipments.length > 0) {
            if (data.Shipments[0].TrackingInfo !== undefined && Object.keys(data.Shipments[0].TrackingInfo).length !== 0) {
              var trackingInfo = data.Shipments[0].TrackingInfo;
              if(trackingInfo.status === "delivered"){
                return "Delivered";
              }
            }
        }
        return data.OrderStatus;
    }, [ordersData]);

    const handleSeeAllOrders = () => {
        setRedirectUrl("/customer");
        setRedirect(true);
    }

    const handleOrderDetails = () => {
      window.open("/orderStatus/" + ordersData[0].ReferenceNumber, "_blank")
    }

    return (
        <>
        {redirect && <Redirect to={redirectUrl} />}
        {pageState === 1 &&
          <Grid container alignItems="center" justify="center" className={classes.root}>
            {!isAdmin && ordersData !== undefined && ordersData !== null && ordersData.length > 0 &&
            <Grid container item xs={10}>
              <Grid item xs={12} className={classes.container}>
                <Typography className={classes.subHeader}>
                  Order No. {ordersData[0].ReferenceNumber}
                </Typography>
                <LaunchRoundedIcon
                  style={{marginLeft: 8, fontSize: 28, cursor: 'pointer'}}
                  className={classes.subHeader}
                  onClick={handleOrderDetails}
                />
              </Grid>
              {isMobile ?
                <MobileOrderTimeLine
                  orderStatus={mostRecentOrderStatus}
                  holdStatus={ordersData[0].HoldStatus}
                /> :
                <OrderTimeLine
                  className={classes.orderTimeline}
                  orderStatus={mostRecentOrderStatus}
                  holdStatus={ordersData[0].HoldStatus}
                />
              }
            </Grid>
            }
            <Grid item xs={10}>
              <Grid container spacing={2} direction='column'>
                <Grid item xs={12} className={classes.container}>
                  <Typography className={classes.subHeader}>
                    Recent Active Orders
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <OrderTable data={ordersData} showCustomer={isAdmin} />
                </Grid>
                <Grid item>
                    <Button color='secondary' onClick={handleSeeAllOrders}>{isAdmin ? "See Customer Orders" : "See All Orders"}</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        {pageState === 2 &&
          <ErrorPage errorCode={500} message={"There was a problem getting the data, please refresh the page"} />
        }
        {pageState === 0 &&
          <Loading text="Loading Dashboard..." />
        }
        </>
    );
}
