/* QuotePdfView.tsx
 * This file renders the main dashboard for BomBuilder. When the website is
 * opened to the index endpoint (/), this is rendered. It shows a dashboard
 * of the latest 20 jobs, as well as the ability to search for a past job,
 * or create a new job.
 */

import React from "react";
import { useSnackbar } from "notistack";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@material-ui/core";
import Lottie from 'react-lottie';
import animationData from "./components/OrderStatus/lottie-circuit-light.json";
import animationDataDark from "./components/OrderStatus/lottie-circuit-dark.json";
import { useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "100vw",
    minHeight: "100vh",
    width: "100%",
    overflowX: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
  },
  searching: {
    width: "80%",
    marginLeft: "10%",
    height: "100vh",
  },
  password: {
    margin: "10%",
    height: "25vh"
  },
  page: {
    width: "100vw",
  },
  paddingTitle: {
    paddingBottom: "4%",
    paddingTop: "1%"
  },
  cardContent: {
    fontSize: '1rem',
    marginLeft: "6%",
    marginRight: "6%",
    marginTop: "2%",
    marginBottom: "4%",
  },
  error: {
    fontSize: '1rem',
    fontWeight: "normal",
    color: "red"
  },
  pageTitle: {
    fontSize: '2rem',
    fontWeight: "bold"
  },
  pageSubtitle: {
    fontSize: '2rem',
    fontWeight: "normal"
  },
  cardTitle: {
    fontSize: '1.5rem',
    fontWeight: "bold"
  },
  cardSubtitle: {
    fontSize: '1.2rem',
    fontWeight: "normal"
  },
  tableHeaderCell: {
    fontSize: '1.2rem',
    fontWeight: "bold",
    maxWidth: "100px"
  },
  tableCell: {
    fontSize: '1rem',
    fontWeight: "normal",
  },
  tableSubheading: {
    fontWeight: "bold",
    paddingTop: "1rem",
    paddingBottom: "1rem"
  },
  tableMargin: {
    paddingLeft: "1%"
  },
  warningTitle: {
    fontSize: '1.5rem',
    fontWeight: "bold",
    color: 'red'
  },
  button: {
    backgroundColor: "#EA723D"
  },
  checkbox: {
    color: theme.palette.primary.light,
    '&.Mui-checked': {
      color: theme.palette.primary.light
    },
  }
}));

const RENDER_QUOTE_PDF_ENDPOINT = "/file/quotePDF/"
const FETCH_QUOTE_DATA_ENDPOINT = "/quoteData/"
const CHECK_QUOTE_ACCESS_PASSWORD = "/checkQuoteAccess";

function QuotePdfView(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [quoteData, setQuoteData] = React.useState(null);
  const [pageState, setPageState] = React.useState(0);
  const [tableColumns, setTableColumns] = React.useState([]);
  const [tableRows, setTableRows] = React.useState([]);
  const [note, setNote] = React.useState("");
  const [discountValue, setDiscountValue] = React.useState("");
  const [discountType, setDiscountType] = React.useState("none");
  const [discountPriceType, setDiscountPriceType] = React.useState("unit");
  const [discountDisclaimer, setDiscountDisclaimer] = React.useState("");
  const [selectAll, setSelectAll] = React.useState(true);
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [authorized, setAuthorized] = React.useState(false);
  const [quoteNumber, setQuoteNumber] = React.useState("");
  const [summitFacility, setSummitFacility] = React.useState("");
  const [summitCSR, setSummitCSR] = React.useState("");
  const [summitTC, setSummitTC] = React.useState("");


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: theme.palette.type === 'dark' ?
                   animationDataDark : animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handlePasswordChange = (e) => {
    setPasswordError("");
    setPassword(e.target.value);
  };

  const handleQuoteNumberChange = (e) => {
    if (isNaN(e.target.value.replace('-', ''))) {
      setErrorMessage("Quote Number must be a number")
    } else {
      setErrorMessage("");
    }
    setQuoteNumber(e.target.value);
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleGo();
    }
  }

  const handleGo = (e) => {
    let value = quoteNumber.split('-');
    let selectedItem = value.length > 1 ? (parseInt(value[1])) : -1;
    if (isNaN(value[0])) {
      setErrorMessage("Quote Number must be a number")
      return "";
    } else {
      setPageState(0);
      fetchData(value[0], selectedItem);
    }
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
  };

  const handleSelectChange = (index) => (e) => {
    setTableRows(
      tableRows.map((item, i) => {
        if (i === index) item.selected = e.target.checked;
        return { selected: item.selected, originalIndex: item.originalIndex, rowValues: item.rowValues }
      })
    );
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  }

  const handleDiscountValueChange = (e) => {
    if (!isNaN(e.target.value)) {
      setDiscountValue(e.target.value);
    }
  }

  const checkPassword = (e) => {
    axios.post("/api" + CHECK_QUOTE_ACCESS_PASSWORD, {
      password: password,
    })
    .then(res => {
      setAuthorized(res.data);
      if (!res.data) {
        setPasswordError("Incorrect Password");
      } else {
        setPageState(1);
      }
    })
    .catch(err => {
      console.log(err);
    });
  };

  const formatCurrency = (value) => {
    if (isNaN(value)) {
      return "";
    }
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(value);
  };


  const fetchData = (quoteId, selectedIndex=-1) => {
    let config = {
      url: '/api' + FETCH_QUOTE_DATA_ENDPOINT + quoteId,
      method: 'get',
      headers: {
        'content-type':'application/json;charset=UTF-8'
      },
      responseType: 'application/json'
    };
    axios(config)
    .then(res => {
      setQuoteData(res.data);

      let lineItems = res.data['lineItems'];
      if (selectedIndex !== -1 && selectedIndex > lineItems.length) setErrorMessage(`Quote does not have ${selectedIndex} items`);
      if (selectedIndex !== -1 && selectedIndex <= lineItems.length) lineItems = [lineItems[selectedIndex-1]];
      if (lineItems.length > 0) {
        setTableColumns([
          { name:"Quantity" },
          { name:"Asm Turn Time" },
          { name:"PCB Turn Time" },
          { name:"Unit Price" },
          { name:"Extended Price" },
          { name: "Additional Services" },
          { name: "NRE" },
          { name: "Total Price"}
        ]);

        let discountAmount = parseFloat(discountValue);
        var discountedLineItems = lineItems.map((item, index) => {
          if (discountType == "none" || isNaN(discountValue)) {
            setDiscountDisclaimer("");
            return item;
          } else {
            item["unit_price"] -= discountPriceType == "unit" ? discountAmount : discountAmount / item["quantity"];
            item["extended_price"] -= discountPriceType == "unit" ? (discountAmount * item["quantity"]) : discountAmount;
            item["total"] -= discountPriceType == "unit" ? (discountAmount * item["quantity"]) : discountAmount;

            if (discountType == "pcb") {
              item["pcb"] -= discountPriceType == "unit" ? discountAmount : discountAmount / item["quantity"];
            } 
            else if (discountType == "parts") {
              item["parts"] -= discountPriceType == "unit" ? discountAmount : discountAmount / item["quantity"];
            } 
            else {
              item["assembly"] -= discountPriceType == "unit" ? discountAmount : discountAmount / item["quantity"];
            }
            let disclaimer = `Discount value of ${formatCurrency(discountAmount)} applied to ${discountPriceType == "unit" ? "unit price" : "total cost"} of ${discountType == "pcb" ? "PCB" : discountType == "parts" ? "Parts" : "Assembly"} charge`;
            setDiscountDisclaimer(disclaimer);
          }
          
          return item;
        });
        setTableRows(discountedLineItems.map((item, index) => {
          return { selected: true, originalIndex: selectedIndex !== -1 ? selectedIndex - 1 : index, rowValues: [
            { value: item["quantity"], breakdown: {Quantity: item['quantity'] }},
            { value: item["asm_turn_time"], breakdown: { AsmTurnTime: item['asm_turn_time'] }},
            { value: item["pcb_turn_time"], breakdown: { PCBTurnTime: item['pcb_turn_time'] }},
            { value: item["unit_price"], breakdown: {"Unit Price": item["unit_price"], "Assembly": item["assembly"], "Components": item["parts"], "PCBs": item["pcb"], }, formatText: formatCurrency },
            { value: item["extended_price"], breakdown: {"Extended Price": item["extended_price"]}, formatText: formatCurrency },
            { value: item["additional"], breakdown: {"Additional Services": item["additional"]}, formatText: formatCurrency },
            { value: item["nre"], breakdown: {"NRE":item["nre"]}, formatText: formatCurrency },
            { value: item["total"], breakdown: {"Total Price": item["total"]}, formatText: formatCurrency },
          ] }
        }))
      }
      setPageState(1);
    })
    .catch(err => {
      switch (err.response.status) {
        case 404:
          setErrorMessage(`Quote: ${quoteNumber} does not exist`);
          break;
        default:
          setErrorMessage(`Quote Service Error. Please refresh page or contact administrator.`);
          break;
      }
      setQuoteNumber("");
      setQuoteData(null);
      setPageState(1);
    })
  };

  const getSelectedItems = () => {
    var selected = tableRows.filter((row) => row.selected === true);
    return selected.map((row) => {
      return row.rowValues.reduce((prev, item) => {
        if (!item.breakdown) return prev;
        return { ...prev, ...item.breakdown, originalIndex: row.originalIndex }
      }, {});
    }, {});
  }

  const handleDownload = (quoteType) => (e) => {
    const selectedLineItems = getSelectedItems();
    const config = {
      /*headers: {
        'content-type':'application/json;charset=UTF-8'
      },*/
      responseType: 'binary'
    };
    quoteData['DiscountDisclaimer'] = discountDisclaimer;
    if (summitCSR != "") {
      quoteData['SalesRepData']['name'] = summitCSR;
      quoteData['SalesRepData']['email'] = `${summitCSR.split(' ')[0]}.${summitCSR.split(' ')[1]}@summitinterconnect.com`;
      quoteData['SalesRepData']['website'] = "www.summitinterconnect.com";
    }
    quoteData['SummitTC'] = summitTC;
    axios.post("/api" + RENDER_QUOTE_PDF_ENDPOINT, {
      quoteData: quoteData,
      selectedLineItems: selectedLineItems,
      note: note,
      quoteType: quoteType,
    }, config)
    .then(res => {
      const url = res.data;//window.URL.createObjectURL(res.data);
      const link = document.createElement('a');
      const filename = quoteType == "e" ? `Quote-${quoteData['QuoteNumber']}.pdf` : `QuoteBreakdown-${quoteData['QuoteNumber']}.pdf`;
      link.target = "_blank";
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      enqueueSnackbar("Downloaded Quote PDF", {variant: 'success'});
    })
    .catch(err => {
      enqueueSnackbar("Download Quote PDF Failed", {variant: 'error'});
      console.log(err);
    });
  };

  React.useEffect(() => {
    setTableRows(
      tableRows.map((item, ) => {
        return { selected: selectAll, originalIndex: item.originalIndex, rowValues: item.rowValues }
      })
    );
  }, [selectAll])

  function Row(props) {
    const { row, index } = props;

    return (
      <TableRow key={index}>
        <TableCell colSpan={2} align="left">
          <Checkbox
            checked={row.selected}
            onChange={handleSelectChange(index)}
            inputProps={{ 'aria-label': 'controlled' }}
            className={classes.checkbox}
          />
        </TableCell>
          {row.rowValues && row.rowValues.map((cell, i) => {
            return (
              <TableCell key={i} size="small" className={classes.tableCell} align="right">
                {cell.formatText ? cell.formatText(cell.value) : cell.value}
              </TableCell>
            )
          })}
      </TableRow>
    )
  }

  function QuoteItemsTable() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} className={classes.tableHeaderCell} align="left">
              Included
            </TableCell>
            {tableColumns.map((column, index) => {
              return (
                <TableCell key={index} className={classes.tableHeaderCell} align="right">
                  {column.name}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row, index) => (
            <Row key={index} row={row} index={index} />
          ))}
        </TableBody>
      </Table>
    )

  }

  return (
    <>
    {!authorized &&
      <Grid container justify="center" className={classes.searching}>
        <Grid container item justify="center" className={classes.password}>
          <Grid item xs={12} className={classes.cardContent} align="center">
            <Typography variant="h5" align="center">Enter password to access this page</Typography>
            <TextField
              id="outlined-multiline-static"
              error={passwordError.length > 0}
              value={password}
              onChange={handlePasswordChange}
              variant="outlined"
              className={classes.cardContent}
              helperText={passwordError}
            />
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={checkPassword} className={classes.button}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    }
    {authorized && pageState === 0 && // loading state
      <Grid container justify="center" className={classes.searching}>
        <Grid item>
          <Lottie options={defaultOptions}
            height={400}
            width={400}
          />
        <Typography variant="h5" align="center">Fetching quote...</Typography>
        </Grid>
      </Grid>
    }
    {authorized && pageState === 1 &&
      <Grid className={classes.root}>
        <Card className={classes.page}>
          <CardContent className={classes.cardContent}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.error}>
                  {errorMessage}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.paddingTitle}>
              <Grid item xs={3}>
                <Typography className={classes.pageTitle}>
                  Quote Number:
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <ButtonGroup>
                  <TextField
                    id="outlined-multiline-static"
                    value={quoteNumber}
                    onChange={handleQuoteNumberChange}
                    onKeyPress={handleKeyPress}
                    variant="outlined"
                    className={classes.pageSubtitle}
                  />
                  <Button variant="outlined" size="large" onClick={handleGo} className={classes.button}>
                    Go
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.cardTitle}>
                  {quoteData ? (quoteData['SpecificationsData']['Project']['ITAR'] === "Yes" ? "ITAR" : "Non-ITAR") : ""}
                </Typography>
                <Typography className={classes.cardTitle}>
                  {quoteData ? `Budgetary: ${quoteData["Budgetary"] ? "Yes" : "No"}` : ""}
                </Typography>
              </Grid>
            </Grid>

            {quoteData && (
            <Grid container spacing={5}>
              <Grid container item>
                <Grid item xs={4}>
                  <Typography className={classes.cardTitle}>
                    Assembly Part #:
                  </Typography>
                  <Typography className={classes.cardSubtitle}>
                    {quoteData['AssemblyPart']}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography className={classes.cardTitle}>
                    Assembly Number:
                  </Typography>
                  <Typography className={classes.cardSubtitle}>
                    {quoteData['AssemblyNumber']}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography className={classes.cardTitle}>
                    Quote Date:
                  </Typography>
                  <Typography className={classes.cardSubtitle}>
                    {moment(quoteData["QuoteDate"]).format('MMM. DD, YYYY')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid item xs={12}>
                  <Divider light />
                </Grid>
              </Grid>
              
              <Grid container item>
                <Grid item xs={4}>
                  <Typography className={classes.cardTitle}>
                    Assembly Type:
                  </Typography>
                  <Typography className={classes.cardSubtitle}>
                    {quoteData['AssemblyType']}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography className={classes.cardTitle}>
                    Order Type:
                  </Typography>
                  <Typography className={classes.cardSubtitle}>
                    {quoteData['OrderType']}
                  </Typography>
                </Grid>

                {quoteData['ReOrderNumber'] ? (
                <Grid item xs={4}>
                  <Typography className={classes.cardTitle}>
                    ReOrder Number:
                  </Typography>
                  <Typography className={classes.cardSubtitle}>
                    {quoteData['ReOrderNumber']}
                  </Typography>
                </Grid>
                ) : null}

              </Grid>


              <Grid container item>
                <Grid item xs={8}>
                  <Typography className={classes.cardTitle}>
                    Quoted By:
                  </Typography>
                  {Object.entries(quoteData['SalesRepData']).map(([key, value], index) => {
                    return (
                      <Typography key={index} className={classes.cardSubtitle}>
                        {value}
                      </Typography>
                    )
                  })}
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.cardTitle}>
                    Prepared For:
                  </Typography>
                  {Object.entries(quoteData['CompanyData']).map(([key, value], index) => {
                    return (
                      <Typography key={index} className={classes.cardSubtitle}>
                        {value}
                      </Typography>
                    )
                  })}
                </Grid>
              </Grid>

              <Grid container item>
                <Grid item xs={12}>
                  <Divider light />
                </Grid>
              </Grid>

              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.cardTitle}>
                    Promotion / Discount Value
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel>Choose which cost to subtract discount value ($) from:</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="none"
                      name="radio-buttons-group"
                      value={discountType}
                      onChange={(e) => setDiscountType(e.target.value)}
                    >
                      <FormControlLabel value="none" control={<Radio />} label="None" />
                      <FormControlLabel value="pcb" control={<Radio />} label="PCB" />
                      <FormControlLabel value="parts" control={<Radio />} label="Parts" />
                      <FormControlLabel value="assembly" control={<Radio />} label="Assembly" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel>Discount Price Type:</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="unit"
                      name="radio-buttons-group"
                      value={discountPriceType}
                      onChange={(e) => setDiscountPriceType(e.target.value)}
                    >
                      <FormControlLabel value="unit" control={<Radio />} label="Unit" />
                      <FormControlLabel value="total" control={<Radio />} label="Total" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                <TextField
                    id="outlined-multiline-static"
                    value={discountValue}
                    disabled={discountType == "none"}
                    onChange={handleDiscountValueChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    multiline
                    variant="outlined"
                    rows={1}
                  />
                </Grid>
                <Grid item xs={10}>
                <Button variant="outlined" size="large" disabled={isNaN(parseFloat(discountValue))} onClick={handleGo} className={classes.button}>
                    Recalculate Pricing
                  </Button>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item className={classes.tableMargin} xs={12}>
                  <FormControlLabel
                    label="Select / Unselect All"
                    control={
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                        inputProps={{ 'aria-label': 'controlled' }}
                        className={classes.checkbox}
                      />
                    }
                  />
                </Grid>
                {discountDisclaimer.length > 0 ? (
                <Grid item className={classes.tableMargin}>
                  <Typography className={classes.tableSubheading}>* {discountDisclaimer}</Typography>
                </Grid>
                ): null}
                <Grid item xs={12}>
                  <QuoteItemsTable />
                </Grid>
              </Grid>

              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.cardTitle}>
                    Notes
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-multiline-static"
                    value={note}
                    onChange={handleNoteChange}
                    multiline
                    variant="outlined"
                    rows={4}
                    fullWidth
                  />
                </Grid>
              </Grid>

              {quoteData['Warnings'] ? (
              <Grid container item>
                <Grid item>
                  <Typography className={classes.warningTitle}>
                      Warnings:
                  </Typography>
                  {quoteData['Warnings'].map((value, index) => {
                    return (
                      <Typography key={index} className={classes.cardSubtitle}>
                        {value}
                      </Typography>
                    )
                  })}
                </Grid>
              </Grid>
              ) : null}

              <Grid container item xs={12} spacing={3}>
                <Grid item xs={3}>
                  <Button variant="outlined" onClick={handleDownload('e')} className={classes.button}>
                    Download Customer Quote
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button variant="outlined" onClick={handleDownload('i')} className={classes.button}>
                    Download Quote Breakdown
                  </Button>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={4}>
                  <Button variant="outlined" onClick={handleDownload(summitFacility)} className={classes.button}>
                    Download Customer Quote as Summit
                  </Button>
                </Grid>
                <Grid item xs={2}>
                <TextField
                  id="outlined-multiline-static"
                  placeholder="Summit Facility Name"
                  value={summitFacility}
                  onChange={(e) => setSummitFacility(e.target.value)}
                  variant="outlined"
                  className={classes.pageSubtitle}
                />
                <TextField
                  id="outlined-multiline-static"
                  placeholder="CSR Full Name"
                  value={summitCSR}
                  onChange={(e) => setSummitCSR(e.target.value)}
                  variant="outlined"
                  className={classes.pageSubtitle}
                />
                <TextField
                  id="outlined-multiline-static"
                  placeholder="T&C URL"
                  value={summitTC}
                  onChange={(e) => setSummitTC(e.target.value)}
                  variant="outlined"
                  className={classes.pageSubtitle}
                />
                </Grid>
              </Grid>

            </Grid>
          )}
          </CardContent>
        </Card>
      </Grid>
    }
    </>
  );
}

export default QuotePdfView;
