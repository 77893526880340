/* DashboardView.tsx
 * This file renders the main dashboard for BomBuilder. When the website is
 * opened to the index endpoint (/), this is rendered. It shows a dashboard
 * of the latest 20 jobs, as well as the ability to search for a past job,
 * or create a new job.
 */

import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import parse from "html-react-parser";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: 800,
    height: 600,
    overflow: 'auto',
    margin: theme.spacing(2),
  },
  spacing: {
    margin: theme.spacing(2),
  },
}));

function TicketCard(props) {
  const classes = useStyles();

  const formatTime = (timestamp) => {
    return moment(timestamp).format('MMM D, YYYY @ h:mm a');
  }


  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.spacing}
            title={props.subject}
            subheader={`Created ${formatTime(props.created_at)}`}
          />
          <CardContent>
            {props.comments && props.comments.map(cmt =>
              <Card className={classes.spacing}>
                <CardHeader
                  subheader={formatTime(cmt.created_at)}
                />
                <CardContent>
                  <Typography paragraph>
                    {parse(cmt.html_body)}
                  </Typography>
                  {cmt.attachments.length > 0 &&
                   cmt.attachments.map(attachment =>
                    <a href={attachment.content_url} style={{textDecoration: 'none'}}>
                      <Button variant="outlined">
                        {attachment.file_name}
                      </Button>
                    </a>
                  )}
                </CardContent>
              </Card>
            )}
          </CardContent>
          <CardActions>
            <Button style={{marginLeft: 'auto'}} variant="outlined">
              Minimize
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>

  );
}

export default TicketCard;
