import React, {useState, useEffect} from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import axios from 'axios';
import * as validator from "email-validator";

import AddTeamMembers from './AddTeamMembers';
import Loading from '../common/Loading';


import{
    Grid,
    Button,
    TextField,
    CircularProgress,
    Typography,
    makeStyles,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "90%",
      marginLeft: "5%",
      marginRight: "5%",
      marginTop: "2%",
      overflow: "hidden"
    },
    displayName: {
      marginLeft: 20,
      fontSize: 36,
      fontWeight: "bold",
    },
    avatarHeader: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    avatar: {
      height: 50,
      width: 50,
      fontSize: 36,
    },
    header: {
      fontSize: 32,
      marginTop: 20,
      marginBottom: 20,
    },
    sectionHeader: {
      fontSize: 26,
      marginTop: 20,
      marginBottom: 20,
    },
    sectionSubheader: {
      fontSize: 20,
      fontWeight: 550,
      marginBottom: 20,
    },
    button: {
      marginLeft: "10%"
    }
}));

const GET_TEAM_MEMBERS = '/getTeamMembers/';
const GET_INVITED_TEAM_MEMBERS = '/getInvitedMembers/';
const ADD_EMAIL_ALIAS = '/addEmailAlias';
const REMOVE_ALIAS_ENDPOINT = '/removeEmailAlias';

export default function Account(){

    const classes = useStyles();

    const [userId, setUserId] = useState(null);
    const [user, setUser] = useState(null);
    const [isPrimaryContact, setIsPrimaryContact] = useState(false);
    const [allMembers, setAllMembers] = useState([]);
    const [invitedMembers, setInvitedMembers] = useState([]);
    const [alias, setAlias] = useState("");
    const [emailAliasError, setEmailAliasError] = useState(null);

    const handleResetPassword = () => {
        var auth = firebase.auth();
        var user = auth.currentUser;
        auth.sendPasswordResetEmail(user.email).then(function() {
            alert("A reset password link has been sent to your email. Please follow that link and sign-in again");
            auth.signOut();
        }).catch(function(error) {
            alert("There was an error sending the reset password link. Please try again in some time");
        });
    }

    const handleEmailAlias = (e) => {
      setAlias(e.target.value);
      setEmailAliasError(null);
    }

    const getTeamMembersTable = () => {
      axios.get('/api' + GET_TEAM_MEMBERS + firebase.auth().currentUser.uid)
      .then(res => {
          setAllMembers(res.data.members);
          setIsPrimaryContact(res.data.isPrimaryContact);
          setUser(res.data.userInfo);
          setUserId(firebase.auth().currentUser.uid);
      })
      .catch(err => {
          console.log("get team members error", err);
      });
      axios.get('/api' + GET_INVITED_TEAM_MEMBERS + firebase.auth().currentUser.uid)
      .then(res => {
        if (res.status === 200) {
          setInvitedMembers(Object.values(res.data));
        }
      })
      .catch(err => console.log("Get invited members error"));
    }

    useEffect(() => {
      var user = firebase.auth().currentUser;
      if (user) {
        firebase.analytics().logEvent('Account', {email: user.email});
      }
      getTeamMembersTable();
    }, []);

    const addEmailAlias = (e) => {
      if (!validator.validate(alias)) {
        setEmailAliasError("Please enter a valid email address");
      } else {
        axios.post('/api' + ADD_EMAIL_ALIAS, {
          aliasKey: alias,
          uid: firebase.auth().currentUser.uid
        })
        .then(res => {
          alert('Alias added successfully');
          setAlias("");
          getTeamMembersTable();
        })
        .catch(err => {
            console.log("add email alias error");
        })
      }
    }

    const handleRemoveAlias = (key) => {
      axios.post('/api' + REMOVE_ALIAS_ENDPOINT, {
        aliasKey: key,
        uid: userId
      })
      .then(res => {
        alert("The alias has been removed");
        getTeamMembersTable();
      })
      .catch(err => {
        alert("There was a problem while removing the alias. Please try again");
      })
    }

    return(
      <>
      {user &&
          <Grid container direction='column' spacing={3} className={classes.root}>
              <Grid item>
                <Typography className={classes.header}>My Account</Typography>
              </Grid>
              <Grid item className={classes.avatarHeader}>
                  <Avatar className={classes.avatar}>{user.name[0]}</Avatar>
                  <span className={classes.displayName}>{user.name}</span>
              </Grid>
              <Grid item>
                <Typography className={classes.sectionHeader}>Contact Information</Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Name"
                      variant="outlined"
                      disabled
                      fullWidth
                      value={user.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Company"
                      variant="outlined"
                      disabled
                      fullWidth
                      value={user.company}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Team"
                      variant="outlined"
                      disabled
                      fullWidth
                      value={user.teamId}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Primary Email"
                      variant="outlined"
                      disabled
                      fullWidth
                      value={user.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Notification Phone"
                      variant="outlined"
                      disabled
                      fullWidth
                      value={user.notificationPhone ?? ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Notification Email"
                      variant="outlined"
                      disabled
                      fullWidth
                      value={user.notificationEmail ?? ""}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container direction='column' spacing={3}>
                {user.emailAliases !== undefined && Object.keys(user.emailAliases).length > 0 &&
                  <Grid item xs={6}>
                    <Typography className={classes.sectionHeader}>Email Aliases</Typography>
                    <TableContainer component={Paper}>
                      <Table stickyHeader>
                        <TableBody>
                          {Object.keys(user.emailAliases).map((key) => {
                            return(
                              <TableRow key={key}>
                                <TableCell>{user.emailAliases[key]}</TableCell>
                                <TableCell align="right">
                                  <Button onClick={() => handleRemoveAlias(key)}>
                                    <ClearIcon style={{color: 'red'}}/>
                                  </Button>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                }
              </Grid>
              <Grid container item direction='column'>
                <Grid item>
                  <Typography className={classes.sectionSubheader}>Add Email Alias</Typography>
                </Grid>
                <Grid container item alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          id="member-email"
                          label="Email Address"
                          variant="outlined"
                          helperText={emailAliasError}
                          value={alias}
                          onChange={handleEmailAlias}
                          error={emailAliasError !== null}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                          disabled={alias.length === 0}
                          onClick={addEmailAlias}
                          color="primary"
                          variant="outlined"
                        >
                          Submit
                        </Button>
                    </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <AddTeamMembers userId={userId} user={user} members={allMembers} invitedMembers={invitedMembers} isPrimaryContact={isPrimaryContact}/>
              </Grid>
              <Grid item>
                  <Button onClick={handleResetPassword} variant="outlined" color="secondary">Reset password</Button>
              </Grid>
              <Grid item></Grid>
          </Grid>
      }
      {!user && <CircularProgress /> }
      </>
    );
}
