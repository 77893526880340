import React, {useEffect, useState} from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from "firebase/app";
import "firebase/auth";
import axios from 'axios';
import {
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
    Button,
    Typography
} from "@material-ui/core";

import {Redirect} from 'react-router-dom';

const IS_ADMIN = "/isAdmin/";
const SET_USERNAME_ENDPOINT = '/setUserName';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: "10%",
        marginBottom: "20%"
    }
}));

export default function Login(props){

    const classes = useStyles();

    const [user, setUser] = useState(null);
    const [url, setUrl] = useState("/");
    const [redirect, setRedirect] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [displayName, setDisplayName] = useState("");
    const [nameError, setNameError] = useState("");
    


    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: url,
        signInOptions: [
          {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            // Whether the display name should be displayed in Sign Up page.
            requireDisplayName: true,
            signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
            disableSignUp: {
              status: true
            }
          },
        ],
        callbacks: {
            signInSuccessWithAuthResult: function(authResult, redirectUrl) {
                // const userId = currentUser.uid;
                redirectUrl = url;
                firebase.analytics().logEvent('Login', {email: authResult.user.email});
                return false
            },
        }
    };

    const handleDisplayNameSubmit = () => {
        if(displayName === ""){
            setNameError("Please enter a name to continue");
            return;
        }
        setNameError("");
        axios.post('/api' + SET_USERNAME_ENDPOINT, {
            uid: user.uid,
            name: displayName
        })
        .then(res => {
            setShowDialog(false);
            setRedirect(true);
        })
        .catch(err => {
            setShowDialog(false); // if the name doesn't update, this lets the user still use the app, but it will ask again next time they login
            setRedirect(true);
        })
    }

    useEffect(() => {
        firebase.auth().onAuthStateChanged(function(currUser) {
            if (currUser) {
                setUser(currUser);
                if(currUser.displayName === null || currUser.displayName === 'No Name'){
                    setShowDialog(true);
                    setRedirect(false);
                }
                else{
                    setRedirect(true);
                }
            }
        });
        if(props.location.state !== undefined){
            setUrl(props.location.state.url)
        }
    }, [])

    return (
        <>
            {user &&
            <>
                <Dialog
                    open={showDialog}
                    aria-labelledby="form-dialog-title"
                    aria-describedby="simple-modal-description"
                >
                    <DialogTitle id="form-dialog-title">Please enter your name</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            variant="outlined"
                            onChange={(e) => setDisplayName(e.target.value)}
                            value={displayName}
                            label={'Name'}
                            helperText={nameError}
                            error={nameError !== ""}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleDisplayNameSubmit}
                            color='primary'
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                {redirect &&
                    <Redirect to={url}/>
                }
            </>
            }

            { !user &&
            <div className={classes.root}>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </div>
            }
        </>
    );
}
