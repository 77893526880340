import React, {useState, useEffect} from "react";
import axios from 'axios';
import {useParams, withRouter, Redirect, useLocation } from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/auth";
// import FileDownload from "js-file-download";
import {
    Divider,
    Grid,
    makeStyles,
    Theme,
    Typography,
    Card,
    CardContent,
    useMediaQuery,
} from "@material-ui/core";

import AssignmentIcon from '@material-ui/icons/Assignment';
import Lottie from 'react-lottie';
import animationData from "./lottie-circuit-light.json";
import animationDataDark from "./lottie-circuit-dark.json";
import { useTheme } from '@material-ui/core/styles';
import OrderTimeLine from './OrderTimeLine';
import DeliveryTimeLine from './DeliveryTimeLine';
import OrderOverview from '../../common/OrderOverview';
import MobileOrderTimeLine from './MobileOrderTimeLine';
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      overflowX: 'hidden'
    },
    header: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    body: {
      width: "80%",
      marginLeft: "10%",
      margin: theme.spacing(3)
    },
    searching: {
      width: "80%",
      marginLeft: "10%",
      height: "100vh",
    },
    refNum: {
      fontSize: "2em",
      fontWeight: "bold",
      margin: theme.spacing(1),
    },
    icon: {
      fontSize: '4rem',
      fill: '#ED8B3E',
    },
    card: {
      borderRadius: "10px",
      marginTop: "1%",
      title: {
        fontWeight: "bold"
      }
    },
    cardSubtitle: {
      fontSize: '1.5rem',
      fontWeight: "bold"
    },
    cardContent: {
      fontSize: '1rem',
    },
    vl: {
      borderLeft: "1px solid black",
      marginLeft: "50%",
      display: {
        xs: 'none', sm: 'block'
      }
    },
    deliveryTimeLine: {
      marginTop: "3%",
      width: "100%",
      marginLeft: theme.spacing(1),
    },
    loading: {
      width: "30%",
      marginLeft: "35%"
    },
    labels: {
        fontSize: 22,
        marginTop: "1%",
        fontWeight: "bold",
        marginBottom: "2%"
    },
}));


// Main OrderStatus Component that calls other related components like TimeLine
function OrderStatus(props){

    const isMobile = !useMediaQuery('(min-width:600px)');

    const GET_ORDER_STATUS_ENDPOINT = "/orderDetailsByUniqueId";

    // state variables
    const [orderData, setOrderData]= useState({});
    const [redirect, setRedirect] = useState(false);
    const [currUrl, setCurrUrl] = useState(useLocation().pathname);
    const [referenceNumber, setReferenceNumber] = useState("");
    const [orderStatus, setOrderStatus] = useState("");
    const [holdStatus, setHoldStatus] = useState("");
    const [timeStamp, setTimeStamp] = useState([]);
    const [pageState, setPageState] = useState(0); // 0 - pulling data | 1 - received valid data | 2 - error
    const [arrivalDate, setArrivalDate] = useState("");
    const [trackingInfo, setTrackingInfo] = useState({});
    const [deliveryTitle, setDeliveryTitle] = useState("Expected Ship Date");
    const [showTrackingDetails, setShowTrackingDetails] = useState(false);
    const classes = useStyles();
    const theme = useTheme();

    let {uniqueOrderId} = useParams();

    const formatTime = (timestamp) => {
      return moment(timestamp).format("MMM D, YYYY");
    }

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: theme.palette.type === 'dark' ?
                     animationDataDark : animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const handleSetState = (data) => {
      setOrderData(data);
      setReferenceNumber(data.ReferenceNumber);
      setOrderStatus(data.OrderStatus);
      setHoldStatus(data.HoldStatus);

      setTimeStamp([
        formatTime(data.OrderDate * 1000),
      ]);

      if (data.EngTimeStamp) {
        setTimeStamp((curr) => [
          ...curr,
          formatTime(data.EngTimeStamp * 1000),
        ]);
      }
      if (data.ProdTimeStamp) {
        setTimeStamp((curr) => [
          ...curr,
          formatTime(data.ProdTimeStamp * 1000),
        ]);
      }
      if (data.ShipmentTimeStamp) {
        setTimeStamp((curr) => [
          ...curr,
          formatTime(data.ShipmentTimeStamp * 1000)
        ]);
        if (data.Shipments?.length > 0) {
          setShowTrackingDetails(true);
        }
      }
      let deliveredTimeStamp = moment('1-1-1');
      let allShipped = data.Shipments?.reduce((allShipped: boolean, shipment: any) => {
        if (shipment.TrackingInfo && shipment.TrackingInfo?.status == "delivered") {
          let timestamp = shipment.TrackingInfo.tracking_details[(shipment.TrackingInfo.tracking_details).length - 1].datetime.split("T")[0];
          deliveredTimeStamp = moment.max([deliveredTimeStamp, moment(timestamp)]);
        }
        return allShipped && shipment.TrackingInfo?.status == "delivered";
      }, true);
      if (allShipped) {
        setTimeStamp((curr) => [
          ...curr,
          deliveredTimeStamp.format('MMM D, YYYY')
        ]);
        setOrderStatus("Delivered");
      }
      setPageState(1);
    }

    const arrivedDaysAgo = (date, days) => {
      let today = moment();
      return today.diff(moment(date), 'days') > days;
    };

    // backend call to get order-status details
    const getOrderData = (user) => {
      axios.post("/api" + GET_ORDER_STATUS_ENDPOINT, {
        uniqueOrderId: uniqueOrderId,
        uid: user.uid
      })
      .then((res) => {
        var data = res.data;
        document.title = 'Order ' + (Object.keys(data).length > 0 ?
                                    '#' + uniqueOrderId :
                                    "Not Found");
        handleSetState(data);
        if (Object.keys(data).length > 0) {
          if (!["Shipped", "Delivered", "Completed"].includes(data.OrderStatus)) {
            firebase.analytics().logEvent('Order Status Active', {email: user.email, orderId: data.ReferenceNumber});
          } else {
            firebase.analytics().logEvent('Order Status Shipped', {email: user.email, orderId: data.ReferenceNumber});
          }
        } else {
          firebase.analytics().logEvent('Order Status Not Found', {email: user.email, orderId: 'Not Found'});
        }
      })
      .catch((err) => {
        setPageState(2);
        console.error(err);
      })
    }

    // below 'useEffect' runs only when the component is mounted for the first time, not on component updates. Remove '[]' to run everytime...
    useEffect(() => {
      firebase.auth().onAuthStateChanged(function(currUser) {
          if (currUser) {
            if (props.location.state !== undefined){
                var data = props.location.state.data;
                document.title = 'Order ' + (data.ReferenceNumber ?
                                            '#' + data.ReferenceNumber :
                                            "Not Found");

              handleSetState(data);
            }
            else {
            getOrderData(currUser);
            }
          } else {
            setRedirect(true);
          }
      });
    }, []);

    return(
      <>
      {redirect && <Redirect to={{
            pathname: "/login",
            state: { url: currUrl }
          }} />}
      <div className={classes.body}>
      {pageState === 0 && // loading state
        <Grid container justify="center" className={classes.searching}>
          <Grid item>
            <Lottie options={defaultOptions}
              height={400}
              width={400}
            />
          <Typography variant="h5" align="center">Searching for your order...</Typography>
          </Grid>
        </Grid>
      }
      { pageState === 1 && // data received
        <Grid className={classes.root}>
          <Grid container>
            <Grid item className={classes.header} xs={12}>
              <AssignmentIcon className={classes.icon} />
              <Typography className={classes.refNum}> Order No. {referenceNumber} </Typography>
            </Grid>
            { isMobile ?
              <MobileOrderTimeLine orderStatus={orderStatus} holdStatus={"OffHold"}/>
              :
              <OrderTimeLine orderStatus={orderStatus} holdStatus={holdStatus} timeStamp={timeStamp}/>
            }

            <Grid item xs={12}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <Grid item xs={12} className={classes.cardSubtitle}>
                          Part Number
                        </Grid>
                        <Grid item xs={12} className={classes.cardContent}>
                          {orderData.ProjectName ? orderData.ProjectName : " - "}
                        </Grid>
                    </Grid>

                    <Grid item sm={1}>
                      <Divider orientation="vertical" className={classes.vl} />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Grid item xs={12} className={classes.cardSubtitle}>
                          PO Number
                        </Grid>
                        <Grid item xs={12} className={classes.cardContent}>
                          {orderData.PurchaseOrder ? orderData.PurchaseOrder : "-"}
                        </Grid>
                    </Grid>

                    <Grid item sm={1}>
                      <Divider orientation="vertical" className={classes.vl} />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Grid item xs={12} className={classes.cardSubtitle}>
                          Order Placed
                        </Grid>
                        <Grid item xs={12} className={classes.cardContent}>
                          {formatTime(orderData.OrderDate * 1000)}
                        </Grid>
                    </Grid>

                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <DeliveryTimeLine
              showTrackingDetails={showTrackingDetails}
              shipments={orderData.Shipments ?? {}}
              shipDate={orderData.ShipDate * 1000}
            />
          </Grid>
          <OrderOverview data={orderData}/>
        </Grid>
      }
      {pageState === 2 && // invalid uniqueOrderId
      <Grid container justify="center" className={classes.searching}>
        <Typography>
          We couldn't find an order with the provided unique id {uniqueOrderId}.
          Please try again, or send an email to support@aapcb.com to get
          the correct ID for your order(s).
        </Typography>
      </Grid>
      }
      </div>
      </>
    );
}

export default withRouter(OrderStatus);
