/* QuoteView.tsx
 * This file renders the main dashboard for BomBuilder. When the website is
 * opened to the index endpoint (/), this is rendered. It shows a dashboard
 * of the latest 20 jobs, as well as the ability to search for a past job,
 * or create a new job.
 */

import React from "react";
import {
  Grid,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  close: {
    padding: theme.spacing(0.5),
  },
  dashboardHeader: {
    fontSize: 35,
    fontWeight: "bold",
  },
  header: {
    marginBottom: 100,
  },
  sectionHeader: {
    fontSize: 28,
    fontWeight: "bold",
  },
  appBar: {
    width: "100vw",
    background: "white",
    color: "black",
    minHeight: 75,
  },
  leftSide: {
    marginLeft: "auto",
  },
  signInButton: {
    marginLeft: "auto",
    color: theme.palette.secondary.light,
    pointerEvents: "none",
  },
}));

function FooterBar() {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container direction="column" wrap="nowrap">
      <Grid item>
        <Toolbar className={classes.appBar}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography>© 2020 Advanced Assembly</Typography>
            </Grid>
            <Grid item>
                <Typography>
                  <a href="tel:800-838-5650">
                    (800)-838-5650
                  </a> || <a href="mailto:sales@aapcb.com">
                    sales@aapcb.com
                  </a>
                </Typography>
            </Grid>
            <Grid item>
              <a href="https://aapcb.com/warranty/">
                <Typography>Warranty & Policies</Typography>
              </a>
            </Grid>
          </Grid>
        </Toolbar>
      </Grid>
    </Grid>
  );
}

export default FooterBar;
