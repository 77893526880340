/* CustomQuoteCard.tsx
 * This file renders the main dashboard for BomBuilder. When the website is
 * opened to the index endpoint (/), this is rendered. It shows a dashboard
 * of the latest 20 jobs, as well as the ability to search for a past job,
 * or create a new job.
 */

import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  makeStyles,
  withStyles,
  TextField,
  Theme,
  Toolbar,
  Tooltip,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: "hidden",
    height: "100%",
    backgroundColor: theme.palette.primary.light,
  },
  spacing: {
    margin: theme.spacing(2),
  },
  card: {
    marginTop: 125,
    minWidth: 100,
    borderRadius: 20,
    backgroundColor: "#F4F4F4",
  },
  button: {
    width: "80%",
    margin: "auto",
    backgroundColor: "#B99858",
    color: "white",
    borderRadius: 10,
  }
}));

function CustomQuoteCard() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography paragraph style={{margin: 8, textAlign: "center"}}>
          Have requirements outside of our standard spec? Click below to request
          a custom quote.
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          className={classes.button}
        >
          Get A Custom Quote
        </Button>
      </CardActions>
    </Card>
  );
}

export default CustomQuoteCard;
